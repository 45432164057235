import React, { memo } from "react";
import { Link, useLocation } from "react-router-dom";

const ParamsLink = memo(({ children, to, className = "", deleteParams = [] }) => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  // Delete specified query parameters
  deleteParams.forEach((param) => {
    queryParams.delete(param);
  });

  return (
    <Link
      to={{
        pathname: to,
        search: queryParams.toString() ? `?${queryParams.toString()}` : "",
      }}
      className={className}
    >
      {children}
    </Link>
  );
});

export default ParamsLink;
