import React, { memo } from "react";
import { Col, Container, Row } from "reactstrap";
import { useProfileContext } from "../../context/profile";
import Header from "../UI/Header";
import HeaderTitle from "../UI/HeaderTitle";
import SourceProgess from "./SourceProgess";
import DailyStatistics from "./DailyStatistics";
import MonthlyStatistics from "./MonthlyStatistics";
import Cases from "./Cases";
import PersonalNotes from "./PersonalNotes";

const HireSupplierDashboard = memo(() => {
  const { profile } = useProfileContext();

  return (
    <Container fluid>
      <Header>
        <HeaderTitle>Welcome, {profile?.full_name}</HeaderTitle>
      </Header>

      <div className="w-100">
        <Row>
          <Col sm="4">
            <SourceProgess />
          </Col>
          <Col sm="8">
            <DailyStatistics />
          </Col>
          <Col sm="6">
            <MonthlyStatistics />
          </Col>
          <Col sm="6">
            <PersonalNotes />
          </Col>
          <Col sm="12">
            <Cases />
          </Col>
        </Row>
      </div>
    </Container>
  );
});

export default HireSupplierDashboard;
