import React from "react";
import { Col, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { AsyncPaginate } from "react-select-async-paginate";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import ContactLink from "../../UI/links/ContactLink";

const loadFileHandlerOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/fileHandlers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const UpdateFormHeader = () => {
  const { formData, fileHandler, hireFileHandler, setFileHandler, setHireFileHandler } = useUpdateFormContext();

  return (
    <div className="border-bottom pb-4 mb-4">
      <Row>
        <Col md={6}>
          <p className="m-0">{`NASL-${formData.case_id}`}</p>
          <p className="m-0">Case Type: {formData.case_type}</p>
          <p className="m-0" style={{ textTransform: "capitalize" }}>
            Client: {`${formData.title} ${formData.first_name} ${formData.middle_name} ${formData.last_name}`}
          </p>
          <p className="m-0">Contact number: {<ContactLink>{formData.mobile_no}</ContactLink>}</p>
          <p className="m-0">Vehical Registration No: <span style={{background: '#FCD12A', padding: '0 10px'}}> {formData.vehicle_registration_no !== null ? formData.vehicle_registration_no : "NA"} </span></p>
        </Col>
        <Col md={6}>
          <p className="m-0">Entered by: {formData.created_by !== null ? formData.created_by : "NA"}</p>
          <Row className='mt-2'>
            <Col md={3} sm={4} xs={6}>
              <Label for="fileHandler">File Handler:</Label>
            </Col>
            <Col md={5} sm={4} xs={6}>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="fileHandler"
                id="fileHandler"
                loadOptions={loadFileHandlerOptions}
                value={fileHandler}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
                onChange={(selected) => setFileHandler(selected)}
              />
            </Col>
          </Row>
          <Row className='mt-1'>
            <Col md={3} sm={4} xs={6}>
              <Label for="hireFileHandler">Hire File Handler:</Label>
            </Col>
            <Col md={5} sm={4} xs={6}>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="hireFileHandler"
                value={hireFileHandler}
                id="hireFileHandler"
                loadOptions={loadFileHandlerOptions}
                additional={{
                  page: 1,
                }}
                debounceTimeout={300}
                onChange={(selected) => setHireFileHandler(selected)}
              />
            </Col>
          </Row>
        </Col>        
      </Row>
    </div>
  );
};

export default UpdateFormHeader;
