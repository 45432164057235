import React, { createContext, useContext, useState } from "react";

export const repairContext = createContext({});
export const useRepairContext = () => useContext(repairContext);

const RepairContextProvider = ({ children }) => {
  const [authorizedBy, setAuthorizedBy] = useState(null);
  const [repairOutlay, setRepairOutlay] = useState(null);
  const [repairStatus, setRepairStatus] = useState(null);
  const [authorizeDate, setAuthorizeDate] = useState("");
  const [garageName, setGarageName] = useState("");
  const [repairRefNo, setRepairRefNo] = useState("");
  const [repairStartDate, setRepairStartDate] = useState("");
  const [repairCompletionDate, setRepairCompletionDate] = useState("");
  const [sourceRepairerPaidDate, setSourceRepairerPaidDate] = useState("");
  const [sourceRepairerPaidStatus, setSourceRepairerPaidStatus] = useState(false);
  const [sourceRepairerPaidAmount, setSourceRepairerPaidAmount] = useState("");
  const [naslPaidDate, setNaslPaidDate] = useState("");
  const [naslRepairerPaidStatus, setNaslRepairerPaidStatus] = useState(false);
  const [naslRepairPaidAmount, setNaslRepairPaidAmount] = useState("");
  const [repairLeadSource, setRepairLeadSource] = useState(null);
  const [repairsAuthorize, setRepairsAuthorize] = useState("");
  
  const [isAddNotActive, setIsAddNotActive] = useState(false);
  const [isAddAsReminderActive, setIsAddAsReminderActive] = useState(false);
  const [isSendEmailActive, setIsSendEmailActive] = useState(false);
  const [isSendSMSActive, setIsSendSMSActive] = useState(false);
  const [noteDescription, setNoteDescription] = useState("");
  const [addNoteTo, setAddNoteTo] = useState({ displayToDEO: false, displayToClient: false });
  const [addAsReminderDate, setAddAsReminderDate] = useState("");
  const [sendEmailTo, setSendEmailTo] = useState({ source: false, client: false });
  const [sendSMSTo, setSendSMSTo] = useState("");
  const [sendSMSMobile, setSendSMSMobile] = useState({ clientNumber: "", mobileNumber: "" });

  const values = {
    authorizedBy,
    setAuthorizedBy,
    repairOutlay,
    setRepairOutlay,
    repairStatus,
    setRepairStatus,
    authorizeDate,
    setAuthorizeDate,
    garageName,
    setGarageName,
    repairRefNo,
    setRepairRefNo,
    repairStartDate,
    setRepairStartDate,
    repairCompletionDate,
    setRepairCompletionDate,
    sourceRepairerPaidDate,
    setSourceRepairerPaidDate,
    sourceRepairerPaidStatus,
    setSourceRepairerPaidStatus,
    sourceRepairerPaidAmount,
    setSourceRepairerPaidAmount,
    naslPaidDate,
    setNaslPaidDate,
    naslRepairerPaidStatus,
    setNaslRepairerPaidStatus,
    naslRepairPaidAmount,
    setNaslRepairPaidAmount,
    repairLeadSource,
    setRepairLeadSource,
    repairsAuthorize,
    setRepairsAuthorize,
    
    isAddNotActive, 
    setIsAddNotActive, 
    isAddAsReminderActive, 
    setIsAddAsReminderActive,
    isSendEmailActive, 
    setIsSendEmailActive,
    isSendSMSActive, 
    setIsSendSMSActive,
    noteDescription, 
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile, 
    setSendSMSMobile,
  };

  return <repairContext.Provider value={values}>{children}</repairContext.Provider>;
};

export default RepairContextProvider;
