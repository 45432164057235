import React, { memo } from "react";
import { Button, Spinner } from "reactstrap";

const LoadingButton = memo(({ children, isLoading, onClick, className }) => {
  return (
    <Button color="primary" onClick={onClick} disabled={isLoading} className={className}>
      {isLoading && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
      <span>{children}</span>
    </Button>
  );
});

export default LoadingButton;
