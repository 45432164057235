import React from "react";
import { Col, FormGroup, Input, Label, Row } from "reactstrap";
import CustomDate from "../../UI/forms/CustomDate";
import MobileInput from "../../UI/forms/MobileInput";

const Notes = ({
  isAddNotActive,
  setIsAddNotActive,
  isAddAsReminderActive,
  setIsAddAsReminderActive,
  isSendEmailActive,
  setIsSendEmailActive,
  isSendSMSActive,
  setIsSendSMSActive,
  noteDescription,
  setNoteDescription,
  addNoteTo,
  setAddNoteTo,
  addAsReminderDate,
  setAddAsReminderDate,
  sendEmailTo,
  setSendEmailTo,
  sendSMSTo,
  setSendSMSTo,
  sendSMSMobile,
  setSendSMSMobile,
  validationErrors,
  setValidationErrors,
}) => {
  return (
    <>
      <hr className="mt-4" />
      <Row>
        <Col md={4}>
          <FormGroup className="p-2">
            <Label for="notes">Notes</Label>
            <Input type="textarea" rows="5" cols="40" id="notes" value={noteDescription} onChange={(e) => setNoteDescription(e.target.value)} />
          </FormGroup>
        </Col>

        <Col className="pt-20">
          <Row className="m-0 pl-2">
            <Col md={4}>
              <Input type="checkbox" id="addNote" checked={isAddNotActive} onClick={(e) => setIsAddNotActive(e.target.checked)} />
              <Label for="addNote">Add Note</Label>
            </Col>

            {isAddNotActive && (
              <>
                <Col md={4} sm={6}>
                  <Input
                    type="checkbox"
                    id="displayToDEO"
                    checked={addNoteTo.displayToDEO}
                    onClick={() =>
                      setAddNoteTo({
                        ...addNoteTo,
                        displayToDEO: !addNoteTo.displayToDEO,
                      })
                    }
                  />
                  <Label for="displayToDEO">Display to DEO</Label>
                </Col>
                <Col md={4} sm={6}>
                  <Input
                    type="checkbox"
                    id="displayToClient"
                    checked={addNoteTo.displayToClient}
                    onClick={() =>
                      setAddNoteTo({
                        ...addNoteTo,
                        displayToClient: !addNoteTo.displayToClient,
                      })
                    }
                  />
                  <Label for="displayToClient">Display to Client</Label>
                </Col>
              </>
            )}
          </Row>

          <Row className="m-0 pl-2">
            <Col md={4} sm={4}>
              <Input type="checkbox" id="addAsReminder" checked={isAddAsReminderActive} onClick={(e) => setIsAddAsReminderActive(e.target.checked)} />
              <Label for="addAsReminder">Add as Reminder</Label>
            </Col>

            {isAddAsReminderActive && (
              <Col md={4} sm={4}>
                <CustomDate startDate={addAsReminderDate} setStartDate={setAddAsReminderDate} id={"addAsReminderDate"} />
              </Col>
            )}
          </Row>

          <Row className="m-0 pl-2">
            <Col md={4}>
              <Input type="checkbox" id="sendEmail" checked={isSendEmailActive} onClick={(e) => setIsSendEmailActive(e.target.checked)} />
              <Label for="sendEmail">Send Email</Label>
            </Col>

            {isSendEmailActive && (
              <>
                <Col md={4} sm={4}>
                  <Input type="checkbox" id="source" checked={sendEmailTo.source} onClick={() => setSendEmailTo({ ...sendEmailTo, source: !sendEmailTo.source })} />
                  <Label for="source">Source</Label>
                </Col>

                <Col md={4} sm={4}>
                  <Input type="checkbox" id="client" checked={sendEmailTo.client} onClick={() => setSendEmailTo({ ...sendEmailTo, client: !sendEmailTo.client })} />
                  <Label for="client">Client</Label>
                </Col>
              </>
            )}
          </Row>

          <Row className="m-0 pl-2">
            <Col md={4}>
              <Input
                type="checkbox"
                id="sendSMS"
                checked={isSendSMSActive}
                onClick={() => {
                  setIsSendSMSActive((prev) => {
                    if (prev === true) setSendSMSTo(null);
                    return !prev;
                  });
                }}
              />
              <Label for="sendSMS">Send SMS</Label>
            </Col>

            <Col>
              <Row>
                {isSendSMSActive && (
                  <>
                    <Col md={4} sm={4}>
                      <Input type="radio" id="leadSource" name="sendSMS" checked={sendSMSTo === "leadSource"} onClick={() => setSendSMSTo("leadSource")} />
                      <Label for="leadSource">Lead Source</Label>
                    </Col>
                    <Col md={4} sm={4}>
                      <Input type="radio" id="sendToClient" name="sendSMS" checked={sendSMSTo === "client"} onClick={() => setSendSMSTo("client")} />
                      <Label for="sendToClient">Send To Client</Label>
                    </Col>
                    <Col md={4} sm={4}>
                      <Input type="radio" id="mobileNumber" name="sendSMS" checked={sendSMSTo === "mobileNumber"} onClick={() => setSendSMSTo("mobileNumber")} />
                      <Label for="mobileNumber">Mobile Number</Label>
                    </Col>
                  </>
                )}

                {sendSMSTo === "client" && (
                  <Col md={6} sm={6}>
                    <MobileInput
                      label="Client Number"
                      mame="clientNumberInput"
                      value={sendSMSMobile?.clientNumber || ""}
                      onChange={(value) => setSendSMSMobile((prevState) => ({ ...prevState, clientNumber: value }))}
                      isInvalid={validationErrors.clientNumber}
                      setError={[setValidationErrors, "clientNumber"]}
                    />
                  </Col>
                )}

                {sendSMSTo === "mobileNumber" && (
                  <Col md={6} sm={6}>
                    <MobileInput
                      label="Mobile Number"
                      mame="mobileNumberInput"
                      value={sendSMSMobile?.mobileNumber || ""}
                      onChange={(value) => setSendSMSMobile((prevState) => ({ ...prevState, mobileNumber: value }))}
                      isInvalid={validationErrors.mobileNumber}
                      setError={[setValidationErrors, "mobileNumber"]}
                    />
                  </Col>
                )}
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};

export default Notes;
