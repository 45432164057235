import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from "reactstrap";
import instance from "../../../axiosInstance";
import { useProfileContext } from "../../../context/profile";
import { useUserContext } from "../../../context/security/users";
import useError from "../../../hooks/useError";
import { fileToBase64 } from "../../../utils/forms";
import RequiredLabel from "../../UI/forms/RequiredLabel";

const Attachment = memo(() => {
  const { userId, contractForm, setContractForm, poa, setPoa, poi, setPoi, attachmentTitle, setAttachmentTitle, attachment, setAttachment, attachments, setAttachments } = useUserContext();
  const [validationErrors, setValidationErrors] = useState({
    attachmentTitle: false,
    attachment: false,
  });

  const { handleError } = useError();

  const { profile } = useProfileContext();
  const permissions = profile?.permission?.user;

  const [isAdding, setIsAdding] = useState(false);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [uploading, setUploading] = useState({
    contractForm: false,
    poa: false,
    poi: false,
  });

  const addAttachment = async () => {
    if (!attachmentTitle || !attachment) {
      return setValidationErrors({
        attachmentTitle: !attachmentTitle,
        attachment: !attachment,
      });
    }

    try {
      setIsAdding(true);
      const base64Data = await fileToBase64(attachment);

      const { data } = await instance.post(`/user/addUserAttachment/${userId}`, {
        attachment_title: attachmentTitle,
        attachment_file_name: attachment.name,
        attachment_base64: base64Data,
      });

      setAttachments((prevAttachments) => [
        ...prevAttachments,
        {
          ...data.data,
        },
      ]);
      setAttachmentTitle("");
      setAttachment(null);
    } catch (error) {
      handleError(error);
    } finally {
      setIsAdding(false);
    }
  };

  const contractFormUpdateHandler = async (e) => {
    const file = e.target.files[0];

    try {
      setUploading({ ...uploading, contractForm: true });
      const base64Data = await fileToBase64(file);
      const { data } = await instance.put(`/user/updateAttachment/${userId}`, {
        attachment_title: "contract",
        attachment_base64: base64Data,
      });

      setContractForm({
        name: data.data.file_name,
        file: data.data.href,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setUploading({ ...uploading, contractForm: false });
    }
  };

  const poaUpdateHandler = async (e) => {
    const file = e.target.files[0];

    try {
      setUploading({ ...uploading, poa: true });
      const base64Data = await fileToBase64(file);
      const { data } = await instance.put(`/user/updateAttachment/${userId}`, {
        attachment_title: "poa",
        attachment_base64: base64Data,
      });

      setPoa({
        name: data.data.file_name,
        file: data.data.href,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setUploading({ ...uploading, poa: false });
    }
  };

  const poiUpdateHandler = async (e) => {
    const file = e.target.files[0];

    try {
      setUploading({ ...uploading, poi: true });
      const base64Data = await fileToBase64(file);
      const { data } = await instance.put(`/user/updateAttachment/${userId}`, {
        attachment_title: "poi",
        attachment_base64: base64Data,
      });

      setPoi({
        name: data.data.file_name,
        file: data.data.href,
      });
    } catch (error) {
      handleError(error);
    } finally {
      setUploading({ ...uploading, poi: false });
    }
  };

  const deleteAttachmentHandler = async () => {
    try {
      setIsDeleting(true);
      await instance.delete(`/user/deleteUserAttachment/${deleteId}`);

      setAttachments(attachments.filter((attachment) => attachment.user_attachment_id !== deleteId));
    } catch (error) {
      handleError(error);
    } finally {
      setIsDeleting(false);
      setDeleteId(null);
      toggleModal();
    }
  };

  const toggleModal = () => setModalIsOpen((pre) => !pre);

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="contractForm">Contract Form</Label>
            <CustomInput
              type="file"
              id="contractForm"
              label={contractForm.name || "Choose file"}
              onChange={contractFormUpdateHandler}
              disabled={!permissions?.edit}
              accept=".pdf, .jpg, .jpeg, .png, .doc, .docx, .xls, .xlsx"
            />
            <small className="text-muted">Supported formats: PDF, JPG, JPEG, PNG, DOC, DOCX, XLS, XLSX</small>
            <br />
            {uploading.contractForm && <p className="text-muted">Uploading...</p>}
            {contractForm.file && (
              <a href={contractForm.file} title="Contract Form" download>
                Download file
              </a>
            )}
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="POA">POA</Label>
            <CustomInput type="file" id="POA" label={poa.name || "Choose file"} disabled={!permissions?.edit} onChange={poaUpdateHandler} accept=".pdf, .jpg, .jpeg, .png, .doc, .docx" />
            <small className="text-muted">Supported formats: PDF, JPG, JPEG, PNG, DOC, DOCX</small>
            <br />
            {uploading.poa && <p className="text-muted">Uploading...</p>}
            {poa.file && (
              <a href={poa.file} title="Contract Form" download>
                Download file
              </a>
            )}
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="POI">POI</Label>
            <CustomInput type="file" id="POI" label={poi.name || "Choose file"} onChange={poiUpdateHandler} disabled={!permissions?.edit} accept=".pdf, .jpg, .jpeg, .png, .doc, .docx" />
            <small className="text-muted">Supported formats: PDF, JPG, JPEG, PNG, DOC, DOCX</small>
            <br />
            {uploading.poi && <p className="text-muted">Uploading...</p>}
            {poi.file && (
              <a href={poi.file} title="Contract Form" download>
                Download file
              </a>
            )}
          </FormGroup>
        </Col>
      </Row>

      <h4 className="mt-4">Attachments</h4>

      <Row>
        <Col md={6}>
          <Col md={6} className="m-0 p-0">
            <FormGroup>
              <RequiredLabel htmlFor="attachmentTitle">User Attachment</RequiredLabel>
              <Input
                type="text"
                autoComplete="off"
                name="attachmentTitle"
                id="attachmentTitle"
                placeholder="Description"
                value={attachmentTitle}
                invalid={validationErrors.attachmentTitle}
                disabled={!permissions?.edit}
                onChange={(e) => {
                  const value = e.target.value;
                  setAttachmentTitle(value);
                  setValidationErrors((pre) => ({ ...pre, attachmentTitle: !value }));
                }}
              />
              <small>{validationErrors.attachmentTitle && <span className="text-danger">Description is required</span>}</small>
            </FormGroup>
          </Col>
        </Col>
        <Col md={4}>
          <FormGroup>
            <RequiredLabel htmlFor="attachment">Attachment</RequiredLabel>
            <CustomInput
              type="file"
              id="attachment"
              invalid={validationErrors.attachment}
              disabled={!permissions?.edit}
              onChange={(e) => {
                const file = e.target.files[0];
                setAttachment(file);
                setValidationErrors((pre) => ({ ...pre, attachment: !file }));
              }}
            />
            {validationErrors.attachment && <small className="text-danger">Attachment is required</small>}
          </FormGroup>
        </Col>
        <Col className={`align-self-end ${(validationErrors.attachment || validationErrors.attachmentTitle) && "pb-3"}`}>
          <Button color="primary" className="mb-3" onClick={addAttachment} disabled={isAdding || !permissions?.edit}>
            {isAdding && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>

      <Table responsive>
        <thead>
          <tr>
            <th scope="col">Sr #</th>
            <th scope="col">User Attachment</th>
            <th scope="col">Attachment</th>
            <th scope="col">Actions</th>
          </tr>
        </thead>
        <tbody>
          {attachments.map((attachment, index) => (
            <tr key={attachment.user_attachment_id}>
              <th scope="row">{index + 1}</th>
              <td>{attachment.title}</td>
              <td>{attachment.file}</td>
              <td>
                <a href={attachment.href} title={attachment.file} className="custom-link" download>
                  <FontAwesomeIcon className="cursor-pointer" icon={faDownload} />
                </a>
                {permissions.deleteUserAttachment ? (
                  <FontAwesomeIcon
                    icon={faTrash}
                    className="ml-2 cursor-pointer"
                    onClick={() => {
                      setDeleteId(attachment.user_attachment_id);
                      toggleModal();
                    }}
                  />
                ) : null}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal isOpen={modalIsOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Delete Confirmation</ModalHeader>
        <ModalBody className="m-3">
          <h4>Are you sure you want to delete this attachment?</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={toggleModal}>
            Cancle
          </Button>
          <Button color="danger" onClick={deleteAttachmentHandler} disabled={isDeleting}>
            {isDeleting && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <span>Delete</span>
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  );
});

export default Attachment;
