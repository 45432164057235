import React, { memo } from "react";

import DatePicker from "react-datepicker";

function range(start, stop, step = 1) {
  const result = [];
  for (let i = start; i < stop; i += step) {
    result.push(i);
  }
  return result;
}

const getYear = (date) => date.getFullYear();

const MonthPicker = memo(({ startDate, setStartDate, id = "", className = "" }) => {
  const years = range(1990, getYear(new Date()) + 1, 1);

  return (
    <DatePicker
      dateFormat="MM-yyyy"
      id={id}
      className={`form-control ${className}`}
      placeholderText="Select Month"
      renderCustomHeader={({ date, changeYear }) => (
        <div className="px-4">
          <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} className="form-control">
            {years.map((option) => (
              <option key={option} value={option} className="form-control">
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      selected={startDate}
      showMonthYearPicker
      onChange={(date) => setStartDate(date)}
    />
  );
});

export default MonthPicker;
