import { faDownload, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, Spinner, Table } from "reactstrap";
import instance from "../../../axiosInstance";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import { useProfileContext } from "../../../context/profile";
import useError from "../../../hooks/useError";
import { fileToBase64 } from "../../../utils/forms";

const Attachment = ({ caseId }) => {
  const [isAdding, setIsAdding] = useState(false);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [isDeleting, setIsDeleting] = useState(false);

  const [validationErrors, setValidationErrors] = useState({
    attachmentTitle: false,
    attachment: false,
  });

  const { handleError } = useError();

  const { profile } = useProfileContext();
  const permissions = profile?.permission?.case;

  const toggleModal = () => setModalIsOpen((pre) => !pre);

  const { attachment_description, setAttachmentDescription, attachment_file, setAttachmentFile, attachments, setAttachments } = useClaimFormContext();

  const addToAttachment = async () => {
    if (!attachment_description || !attachment_file) {
      return setValidationErrors({
        attachmentTitle: !attachment_description.trim(),
        attachment: !attachment_file,
      });
    }

    try {
      setIsAdding(true);
      const base64Data = await fileToBase64(attachment_file);

      const { data } = await instance.post(`/case/addAttachment/${caseId}`, {
        attachment_description,
        attachment_file_name: attachment_file.name,
        attachment_base64: base64Data,
      });

      setAttachments((prevAttachments) => [
        ...prevAttachments,
        {
          ...data.data,
          file: attachment_file.name,
        },
      ]);
      setAttachmentDescription("");
      setAttachmentFile(null);
    } catch (err) {
      handleError(err);
    } finally {
      setIsAdding(false);
    }
  };

  useEffect(() => {
    const allAttachments = async () => {
      try {
        const response = await instance.get(`case/view/${caseId}`);
        const data = response.data.data.attachments;
        setAttachments(data);
      } catch (err) {
        handleError(err);
      }
    };
    allAttachments();
  }, []);

  const deleteAttachment = async () => {
    try {
      setIsDeleting(true);
      await instance.delete(`/case/deleteAttachment/${deleteId}`);

      setAttachments(attachments.filter((attachment) => attachment.case_attachment_id !== deleteId));
    } catch (error) {
      handleError(error);
    } finally {
      setIsDeleting(false);
      setDeleteId(null);
      toggleModal();
    }
  };

  return (
    <Form>
      <h4>Attachments</h4>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="description">Description</Label>
            <Input
              type="textarea"
              autoComplete="off"
              rows="1"
              invalid={validationErrors.attachmentTitle}
              id="description"
              disabled={!permissions.edit}
              value={attachment_description}
              onChange={(e) => {
                setAttachmentDescription(e.target.value);
                setValidationErrors((pre) => ({ ...pre, attachmentTitle: !e.target.value }));
              }}
            />
            <small>{validationErrors.attachmentTitle && <span className="text-danger">Description is required</span>}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="file">Select File</Label>
            <CustomInput
              type="file"
              id="contractForm"
              disabled={!permissions.edit}
              invalid={validationErrors.attachment}
              label={attachment_file ? attachment_file.name : "Choose File"}
              onChange={(e) => {
                setAttachmentFile(e.target.files[0]);
                setValidationErrors((pre) => ({ ...pre, attachment: !e.target.value }));
              }}
            />
            {validationErrors.attachment && <small className="text-danger">Attachment is required</small>}
          </FormGroup>
        </Col>
        <Col className={`align-self-end ${(validationErrors.attachment || validationErrors.attachmentTitle) && "pb-3"}`}>
          <Button color="primary" className="mb-3" onClick={addToAttachment} disabled={isAdding || !permissions.edit}>
            {isAdding && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <FontAwesomeIcon icon={faPlus} />
          </Button>
        </Col>
      </Row>

      {attachments.length >= 0 && (
        <Table responsive className="mt-4">
          <thead>
            <tr>
              <th scope="col">Sr. No</th>
              <th scope="col">Description</th>
              <th scope="col">Attachment</th>
              {/* <th scope="col">Date</th> */}
              {permissions?.deleteAttachment ? <th scope="col">Actions</th> : null}
            </tr>
          </thead>
          <tbody>
            {attachments.map((attachment, index) => (
              <tr key={attachment.description}>
                <th scope="row">{index + 1}</th>
                <td>{attachment.description}</td>
                <td>{attachment.file}</td>
                <td>
                <a href={attachment.href} title={attachment.file} className="custom-link" download>
                  <FontAwesomeIcon className="cursor-pointer" icon={faDownload} />
                </a>
                  {permissions?.deleteAttachment ? (
                    <FontAwesomeIcon
                      icon={faTrash}
                      className="ml-2 cursor-pointer"
                      onClick={() => {
                        setDeleteId(attachment.case_attachment_id);
                        toggleModal();
                      }}
                    />
                  ) : null}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}

      <Modal isOpen={modalIsOpen} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>Delete Confirmation</ModalHeader>
        <ModalBody className="m-3">
          <h4>Are you sure you want to delete this attachment?</h4>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" outline onClick={toggleModal}>
            Cancle
          </Button>
          <Button color="danger" onClick={deleteAttachment} disabled={isDeleting}>
            {isDeleting && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <span>Delete</span>
          </Button>
        </ModalFooter>
      </Modal>
    </Form>
  );
};

export default Attachment;
