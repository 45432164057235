import { faCheck, faEquals } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import DashboardCard from "./DashboardCard";

const SourceProgess = memo(() => {
  return (
    <Card>
      <CardBody className="pb-0">
        <CardTitle tag="h5">Source Progress</CardTitle>

        <Row>
          <Col sm="12">
            <DashboardCard title="Total Sources" value="3" className="border" icon={<FontAwesomeIcon icon={faEquals} size="lg" />} bottomMargin="mb-0" />
          </Col>
          <Col sm="12">
            <DashboardCard title="Active Sources" value="1" className="border" icon={<FontAwesomeIcon icon={faCheck} size="lg" />} bottomMargin="mb-0" />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default SourceProgess;
