import React, { useEffect, useState } from "react";
import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from "reactstrap";
import Select from "react-select";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import instance from "../../../axiosInstance";
import { booleanOptions } from "../ClaimForm/ClientDetails";
import { AsyncPaginate } from "react-select-async-paginate";
import useError from "../../../hooks/useError";
import { toDateObj } from "../../../utils/forms";
import CustomDate from "../../UI/forms/CustomDate";
import { titleOptions } from "../AddNewCase/AddNewCaseForm";
import ContactInput from "../../UI/forms/ContactInput";

//fetching Combos/ Dropdown Options
let fetchedAccidentCountriesOptions = [];
const fetchAccidentCountriesOptions = async (search) => {
  try {
    if (search && fetchedAccidentCountriesOptions.length) {
      const filteredOptions = fetchedAccidentCountriesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/accidentCountries");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    fetchedAccidentCountriesOptions = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let fetchedAccidentTypesOptions = [];
const fetchAccidentTypesOptions = async (search) => {
  try {
    if (search && fetchedAccidentTypesOptions.length) {
      const filteredOptions = fetchedAccidentTypesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/caseTypes");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    fetchedAccidentTypesOptions = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const AccidentOrInjury = ({ caseId }) => {
  const {
    accident_type,
    setAccidentType,
    accident_date,
    setAccidentDate,
    accident_time,
    setAccidentTime,
    accident_location,
    setAccidentLocation,
    accident_description,
    setAccidentDescription,
    injury_description,
    setInjuryDescription,
    wearing_seat_belt,
    setWearingSeatBelt,
    google_lat_lon,
    setGoogleLatLon,
    suffering_time,
    setSufferingTime,
    medical_attention,
    setMedicalAttention,
    previous_accidents,
    setPreviousAccidents,
    why_you_not_claim,
    setWhyYouNotClaim,
    sent_for_medical,
    setSentForMedical,
    did_any_paperwork,
    setDidAnyPaperwork,
    reported_to_police,
    setReportedToPolice,
    police_report,
    setPoliceReport,
    were_there_witness,
    setWereThereWitness,
    witness_info,
    setWitnessInfo,
    witness_title,
    setWitnessTitle,
    witness_first_name,
    setWitnessFirstName,
    witness_last_name,
    setWitnessLastName,
    witness_contact,
    setWitnessContact,
    were_there_passenger,
    setWereTherePassenger,
    passenger_info,
    setPassengerInfo,
    liability_terms,
    setLiabilityTerms,
    tp_admit_liability,
    setTpAdmitLiability,
    tp_offered_service,
    setTpOfferedService,
    offered_service_detail,
    setOfferedServiceDetail,
    country_of_accident,
    setCountryOfAccident,
    accidentValidation,
    setAccidentValidation,
  } = useClaimFormContext();

  const { handleError } = useError();

  // ----------------------------- Fetching Data ------------------------------
  useEffect(() => {
    const accidentDetails = async () => {
      try {
        const response = await instance.get(`case/view/${caseId}`);
        const data = response.data.data.accident_detail;

        if (data) {
          setAccidentType({ value: data.accident_type, label: data.accident_type });
          setAccidentDate(toDateObj(data.accident_date));
          setAccidentTime(data.accident_time);
          setAccidentLocation(data.accident_location);
          setAccidentDescription(data.accident_description);
          setInjuryDescription(data.injury_description);
          setWearingSeatBelt({ value: data.wearing_seat_belt, label: data.wearing_seat_belt });
          setGoogleLatLon(data.google_lat_lon);
          setSufferingTime(data.suffering_time);
          setMedicalAttention(data.medical_attention);
          setPreviousAccidents(data.previous_accidents);
          setWhyYouNotClaim(data.why_you_not_claim);
          setSentForMedical({ value: data.sent_for_medical, label: data.sent_for_medical });
          setDidAnyPaperwork({ value: data.did_any_paperwork, label: data.did_any_paperwork });
          setReportedToPolice({ value: data.reported_to_police, label: data.reported_to_police });
          setPoliceReport(data.police_report);
          setWereThereWitness({ value: data.were_there_witness, label: data.were_there_witness });
          setWitnessInfo(data.witness_info);
          setWitnessTitle({ value: data.witness_title, label: data.witness_title });
          setWitnessFirstName(data.witness_first_name);
          setWitnessLastName(data.witness_last_name);
          setWitnessContact(data.witness_contact);
          setWereTherePassenger({ value: data.were_there_passenger, label: data.were_there_passenger });
          setPassengerInfo(data.passenger_info);
          setLiabilityTerms(data.liability_terms);
          setTpAdmitLiability({ value: data.tp_admit_liability, label: data.tp_admit_liability });
          setTpOfferedService({ value: data.tp_offered_service, label: data.tp_offered_service });
          setOfferedServiceDetail(data.offered_service_detail);
          setCountryOfAccident({ value: data.country_of_accident, label: data.country_of_accident });
        }
      } catch (err) {
        handleError(err);
        console.log(err);
      }
    };
    accidentDetails();
  }, []);

  return (
    <Form>
      <h4>Accident/Injury Details</h4>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentType">Accident Type</Label>
            <AsyncPaginate
              className={`basic-single ${accidentValidation.accidentType ? "invalid-select" : ""}`}
              classNamePrefix="select"
              isSearchable
              isClearable
              name="accidentType"
              loadOptions={fetchAccidentTypesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={accident_type}
              onChange={(value) => {
                setAccidentType(value);
                setAccidentValidation({ ...accidentValidation, accidentType: value ? "" : "Accident type is required" });
              }}
            />
            <small className="text-danger">{accidentValidation.accidentType}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentDate">Accident Date</Label>
            <Col md={6} className="pl-0">
              <CustomDate
                id="accidentDate"
                className={accidentValidation.accidentDate ? "is-invalid" : ""}
                startDate={accident_date}
                setStartDate={(date) => {
                  setAccidentDate(date);
                  setAccidentValidation({
                    ...accidentValidation,
                    accidentDate: date ? "" : "Accident date is required",
                  });
                }}
              />
              <small className="text-danger">{accidentValidation.accidentDate}</small>
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentTime">Accident Time</Label>
            <Col md={6} className="pl-0">
              <Input
                type="time"
                id="accidentTime"
                name="accidentTime"
                autoComplete="off"
                timeFormat="HH:mm"
                value={accident_time}
                className={accidentValidation.accidentTime ? "is-invalid" : ""}
                onChange={(e) => {
                  setAccidentTime(e.target.value);
                  setAccidentValidation({
                    ...accidentValidation,
                    accidentTime: e.target.value ? "" : "Accident time is required",
                  });
                }}
              />
              <small className="text-danger">{accidentValidation.accidentTime}</small>
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentLocation">Accident Location</Label>
            <Input type="text" autoComplete="off" id="accidentLocation" name="accidentLocation" value={accident_location} onChange={(e) => setAccidentLocation(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="accidentDescription">Description of Accident</Label>
            <Input
              type="textarea"
              autoComplete="off"
              id="accidentDescription"
              name="accidentDescription"
              rows="1"
              value={accident_description}
              onChange={(e) => setAccidentDescription(e.target.value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="injuryDescription"> Injury</Label>
            <Input type="textarea" autoComplete="off" id="injuryDescription" name="injuryDescription" rows="1" value={injury_description} onChange={(e) => setInjuryDescription(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="wearingSeatBelt">Were you wearing a seatbelt?</Label>
            <Select
              id="wearingSeatBelt"
              name="wearingSeatBelt"
              className="basic-single"
              classNamePrefix="select"
              value={wearing_seat_belt}
              onChange={(value) => setWearingSeatBelt(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="googlelatlon">Google Maps</Label>
            <Input type="text" autoComplete="off" id="googlelatlon" name="googlelatlon" value={google_lat_lon} onChange={(e) => setGoogleLatLon(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="sufferingTime">Suffering Time</Label>
            <Input type="text" autoComplete="off" id="sufferingTime" name="sufferingTime" value={suffering_time} onChange={(e) => setSufferingTime(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="medicalAttention">Medical Attention</Label>
            <Input type="text" autoComplete="off" id="medicalAttention" name="medicalAttention" value={medical_attention} onChange={(e) => setMedicalAttention(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="previousAccident">Have you had any other accidents in the last 3 years?</Label>
            <Input type="text" autoComplete="off" id="previousAccident" name="previousAccident" value={previous_accidents} onChange={(e) => setPreviousAccidents(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="claimHistory">Why have you not made a claim until now?</Label>
            <Input type="textarea" autoComplete="off" id="claimHistory" name="claimHistory" rows="1" value={why_you_not_claim} onChange={(e) => setWhyYouNotClaim(e.target.value)} />
            <small className="text-danger">Only ask if accident is more than 3 months ago</small>
            {/* <div className="text-danger">Only ask if accident is more than 3 months ago</div> */}
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="sentForMedical">Have you been sent for a medical?</Label>
            <Select
              id="sentForMedical"
              name="sentForMedical"
              className="basic-single"
              classNamePrefix="select"
              value={sent_for_medical}
              onChange={(value) => setSentForMedical(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="didAnyPaperwork">Have you spoken or signed any paperwork with any other companies?</Label>
            <Select
              id="didAnyPaperwork"
              name="didAnyPaperwork"
              className="basic-single"
              classNamePrefix="select"
              value={did_any_paperwork}
              onChange={(value) => setDidAnyPaperwork(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="reportedToPolice">Was the accident reported to the police?</Label>
            <Select
              id="reportedToPolice"
              name="reportedToPolice"
              className="basic-single"
              classNamePrefix="select"
              value={reported_to_police}
              onChange={(value) => setReportedToPolice(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {reported_to_police.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="policeReport">Police Report Details</Label>
              <Input type="textarea" autoComplete="off" id="policeReport" name="policeReport" rows="1" value={police_report} onChange={(e) => setPoliceReport(e.target.value)} />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="anyWitnesses">Were there witnesses?</Label>
            <Select
              id="anyWitnesses"
              name="anyWitnesses"
              className="basic-single"
              classNamePrefix="select"
              value={were_there_witness}
              onChange={(value) => setWereThereWitness(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {were_there_witness.value === "Yes" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessInfo">Witness Info</Label>
                <Input type="textarea" autoComplete="off" id="witnessInfo" name="witnessInfo" rows="1" value={witness_info} onChange={(e) => setWitnessInfo(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessTitle">Witnesses Title</Label>
                <Select
                  className={`basic-single`}
                  classNamePrefix="select"
                  id="witnessTitle"
                  name="witnessTitle"
                  value={witness_title}
                  options={titleOptions}
                  onChange={(value) => {
                    setWitnessTitle(value);
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessFirstName">Witnesses First Name</Label>
                <Input type="text" autoComplete="off" id="witnessFirstName" name="witnessFirstName" value={witness_first_name} onChange={(e) => setWitnessFirstName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="witnessLastName">Witnesses Last Name</Label>
                <Input type="text" autoComplete="off" id="witnessLastName" name="witnessLastName" value={witness_last_name} onChange={(e) => setWitnessLastName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <ContactInput
                name="witnessContact"
                label="Witnesses Contact"
                value={witness_contact}
                onChange={setWitnessContact}
                isInvalid={accidentValidation.witnessContact}
                setError={[setAccidentValidation, "witnessContact"]}
              />
            </Col>
          </>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="anyPassenger">Were there any passengers in the vehicle?</Label>
            <Select
              id="anyPassenger"
              name="anyPassenger"
              className="basic-single"
              classNamePrefix="select"
              value={were_there_passenger}
              onChange={(value) => setWereTherePassenger(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {were_there_passenger.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="passengerInfo">Seating Plan</Label>
              <Input type="textarea" autoComplete="off" id="passengerInfo" name="passengerInfo" rows="1" value={passenger_info} onChange={(e) => setPassengerInfo(e.target.value)} />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="liabilityTerms">What has happened in terms of liability?</Label>
            <Input type="textarea" autoComplete="off" id="liabilityTerms" name="liabilityTerms" rows="1" value={liability_terms} onChange={(e) => setLiabilityTerms(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="tpAdmitLiability">Did the TP admit liability at the Scene?</Label>
            <Select
              id="tpAdmitLiability"
              name="tpAdmitLiability"
              className="basic-single"
              classNamePrefix="select"
              value={tp_admit_liability}
              onChange={(value) => setTpAdmitLiability(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="tpOfferedService">Has the TPI offered any types of services or been in contact?</Label>
            <Select
              id="tpOfferedService"
              name="tpOfferedService"
              className="basic-single"
              classNamePrefix="select"
              value={tp_offered_service}
              onChange={(value) => setTpOfferedService(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        {tp_offered_service.value === "Yes" && (
          <Col md={4}>
            <FormGroup>
              <Label for="offeredServiceDetail">Offered Service Details</Label>
              <Input
                type="textarea"
                autoComplete="off"
                id="offeredServiceDetail"
                name="offeredServiceDetail"
                rows="1"
                value={offered_service_detail}
                onChange={(e) => setOfferedServiceDetail(e.target.value)}
              />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="countryOfAccident">Which country did the accident occur in?</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable
              name="countryOfAccident"
              loadOptions={fetchAccidentCountriesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={country_of_accident}
              onChange={(value) => setCountryOfAccident(value)}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default AccidentOrInjury;
