import React, { memo, useState } from "react";
import { useHistory } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import ParamsLink from "./links/ParamsLink";

const Wizard = memo(({ components, isSteps = true, backLink = ".." }) => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [visitedTabs, setVisitedTabs] = useState(isSteps ? [true, ...new Array(components.length - 1).fill(false)] : new Array(components.length).fill(true));
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (index) => {
    if (!isSteps) {
      return setActiveTab(index);
    }

    if (visitedTabs[index]) {
      setActiveTab(index);
    }
  };

  const handleNext = () => {
    setVisitedTabs((prevVisitedTabs) => {
      const newVisitedTabs = [...prevVisitedTabs];
      newVisitedTabs[activeTab + 1] = true;
      return newVisitedTabs;
    });

    setActiveTab((prevActiveTab) => {
      return prevActiveTab + 1;
    });
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    const isValid = await components[activeTab].onSubmit();
    setIsLoading(false);
    if (isValid && activeTab !== components.length - 1 && isSteps) {
      handleNext();
    }

    if (isValid && activeTab === components.length - 1) {
      history.push(backLink);
    }
  };

  const getButtonLabel = () => {
    if (activeTab === components.length - 1) {
      return isSteps ? "Submit" : "Update";
    }

    return isSteps ? "Next" : "Update";
  };

  return (
    <div className="wizard">
      <ul className="tabs">
        {components.map((component, index) => (
          <li key={component.tab} onClick={() => handleTabClick(index)} className={activeTab === index ? "active" : visitedTabs[index] ? "visited" : "disabled"}>
            <h6>{component.tab}</h6>
          </li>
        ))}
      </ul>

      <div className="wizard-content">{components[activeTab].component}</div>

      <div className="wizard-footer">
        <ParamsLink to={backLink}>
          <Button color="primary">Exit</Button>
        </ParamsLink>

        {isSteps && activeTab > 0 && (
          <Button color="primary" onClick={() => setActiveTab((prevActiveTab) => prevActiveTab - 1)}>
            Previous
          </Button>
        )}

        {!components[activeTab].hideSubmit && (
          <Button color="primary" onClick={handleSubmit}>
            {isLoading && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <span>{getButtonLabel()}</span>
          </Button>
        )}
      </div>
    </div>
  );
});

export default Wizard;
