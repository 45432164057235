import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import useError from "../../../hooks/useError";
import { booleanOptions } from "./ClientDetails";
import { validateDecimalInput } from "../../../utils/validations";
import { toDateObj } from "../../../utils/forms";
import CustomDate from "../../UI/forms/CustomDate";

const ClientVehicle = ({ caseId }) => {
  const { handleError } = useError();

  const {
    registration_name,
    setRegistrationName,
    registration_no,
    setRegistrationNo,
    damage_detail,
    setDamageDetail,
    make_model,
    setMakeModel,
    color,
    setColor,
    insurance,
    setInsurance,
    road_worthy,
    setRoadWorthy,
    location,
    setLocation,
    recovered_date,
    setRecoveredDate,
    insurance_type,
    setInsuranceType,
    vehicle_condition,
    setVehicleCondition,
    registration_contact,
    setRegistrationContact,
    v5_available,
    setV5Available,
    insurance_available,
    setInsuranceAvailable,
    driving_license_available,
    setDrivingLicenseAvailable,
    require_rack_box,
    setRequireRackBox,
    require_top_box,
    setRequireTopBox,
    top_box_type,
    setTopBoxType,
    helmet_useable,
    setHelmetUseable,
    using_accessories,
    setUsingAccessories,
    can_transfer_accessories,
    setCanTransferAccessories,
    hot_grips_amount,
    setHotGripsAmount,
    wind_screen_amount,
    setWindScreenAmount,
    muffs_amount,
    setMuffsAmount,
    leg_cover_amount,
    setLegCoverAmount,
    mobile_charger_amount,
    setMobileChargerAmount,
    collect_bike_from_fenchurch,
    setCollectBikeFromFenchurch,
    collect_125cc_from_fenchurch,
    setCollect125ccFromFenchurch,
    clientVehicleValidation,
    setClientVehicleValidation,
  } = useClaimFormContext();

  // ----------------------------- Fetching Data ------------------------------
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await instance.get(`case/view/${caseId}`);
        const data = response.data.data.vehicle_detail;

        const selectedBooleanValue = (field) => {
          return booleanOptions.find((option) => option.value === field);
        };

        if (data) {
          setRegistrationName(data.registration_name);
          setRegistrationNo(data.registration_no);
          setDamageDetail(data.damage_detail);
          setMakeModel(data.make_model);
          setColor(data.color);
          setInsurance(data.insurance);
          setRoadWorthy(selectedBooleanValue(data.road_worthy));
          setLocation(data.location);
          setRecoveredDate(toDateObj(data.recovered_date));
          setInsuranceType(data.insurance_type);
          setVehicleCondition(data.vehicle_condition);
          setRegistrationContact(data.registration_contact);
          setV5Available(selectedBooleanValue(data.v5_available));
          setInsuranceAvailable(selectedBooleanValue(data.insurance_available));
          setDrivingLicenseAvailable(selectedBooleanValue(data.driving_license_available));
          setRequireRackBox(selectedBooleanValue(data.require_rack_box));
          setRequireTopBox(selectedBooleanValue(data.require_top_box));
          setTopBoxType(data.top_box_type);
          setHelmetUseable(selectedBooleanValue(data.helmet_useable));
          setUsingAccessories(selectedBooleanValue(data.using_accessories));
          setCanTransferAccessories(selectedBooleanValue(data.can_transfer_accessories));
          setHotGripsAmount(data.hot_grips_amount);
          setWindScreenAmount(data.wind_screen_amount);
          setMuffsAmount(data.muffs_amount);
          setLegCoverAmount(data.leg_cover_amount);
          setMobileChargerAmount(data.mobile_charger_amount);
          setCollectBikeFromFenchurch(selectedBooleanValue(data.collect_bike_from_fenchurch));
          setCollect125ccFromFenchurch(selectedBooleanValue(data.collect_125cc_from_fenchurch));
        }
      } catch (err) {
        handleError(err);
      }
    };

    getData();
  }, []);

  return (
    <Form>
      <h4>Client Vehicle Details</h4>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="registrationName">Registration Name</Label>
            <Input type="text" autoComplete="off" id="registrationName" value={registration_name} onChange={(e) => setRegistrationName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="registrationNo">Registration Number</Label>
            <Input type="text" autoComplete="off" id="registrationNo" value={registration_no} onChange={(e) => setRegistrationNo(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="damageDetails">Damage Details</Label>
            <Input type="textarea" autoComplete="off" rows="1" id="damageDetails" value={damage_detail} onChange={(e) => setDamageDetail(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="makeModel">Make/Model</Label>
            <Input type="text" autoComplete="off" id="makeModel" value={make_model} onChange={(e) => setMakeModel(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="color">Color</Label>
            <Input type="text" autoComplete="off" id="color" value={color} onChange={(e) => setColor(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="insurance">Insurance</Label>
            <Input type="text" autoComplete="off" id="insurance" value={insurance} onChange={(e) => setInsurance(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="roadWorthy">Is the vehicle Roadworthy?</Label>
            <Select id="roadWorthy" value={road_worthy} onChange={(value) => setRoadWorthy(value)} className="basic-single" classNamePrefix="select" options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="location"> Where is the vehicle at the moment? </Label>
            <Input type="text" autoComplete="off" id="location" value={location} onChange={(e) => setLocation(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="recoveredDate">Vehicle Recovered Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={recovered_date} setStartDate={setRecoveredDate} id="recoveredDate" />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="insurancetype"> Insurance Type </Label>
            {/* dropdown required */}
            <Input type="text" autoComplete="off" id="insurancetype" value={insurance_type} onChange={(e) => setInsuranceType(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="vehicleCondition"> Vehicle Condition </Label>
            <Input type="textarea" autoComplete="off" rows="1" id="vehicleCondition" value={vehicle_condition} onChange={(e) => setVehicleCondition(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            {/* <Label for="registrationContact"> Registration Contact </Label> */}
            <Label for="registrationContact"> How did the bike leave the scene of the accident? </Label>
            <Input type="text" autoComplete="off" id="registrationContact" value={registration_contact} onChange={(e) => setRegistrationContact(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="v5Available">Do you have a hard copy of your V5?</Label>
            <Select id="v5Available" className="basic-single" classNamePrefix="select" value={v5_available} onChange={(value) => setV5Available(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="insuranceAvailable"> Do you have the hard copy of your insurance certificate? </Label>
            <Select id="insuranceAvailable" className="basic-single" classNamePrefix="select" value={insurance_available} onChange={(value) => setInsuranceAvailable(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="drivingLicenseAvailable"> Do you have a hard copy of your driving license? </Label>
            <Select
              id="drivingLicenseAvailable"
              className="basic-single"
              classNamePrefix="select"
              value={driving_license_available}
              onChange={(value) => setDrivingLicenseAvailable(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="requireRackBox"> Require Rack Box ? </Label>
            <Select id="requireRackBox" className="basic-single" classNamePrefix="select" value={require_rack_box} onChange={(value) => setRequireRackBox(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="requireTopBox"> Require Top Box? </Label>
            <Select id="requireTopBox" className="basic-single" classNamePrefix="select" value={require_top_box} onChange={(value) => setRequireTopBox(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="topBoxType"> Top Box Type </Label>
            {/* dropdown required maybe */}
            <Input type="text" autoComplete="off" id="topBoxType" value={top_box_type} onChange={(e) => setTopBoxType(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="helmetUseable"> Helmet Useable? </Label>
            <Select id="helmetUseable" className="basic-single" classNamePrefix="select" value={helmet_useable} onChange={(value) => setHelmetUseable(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="usingAccessories"> Using Accessories? </Label>
            <Select id="usingAccessories" className="basic-single" classNamePrefix="select" value={using_accessories} onChange={(value) => setUsingAccessories(value)} options={booleanOptions} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="canTransferAccessories"> Can Transfer Accessories? </Label>
            <Select
              id="canTransferAccessories"
              className="basic-single"
              classNamePrefix="select"
              value={can_transfer_accessories}
              onChange={(value) => setCanTransferAccessories(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hotGripsAmount"> Hot Grips Amount </Label>
            <Input
              type="text"
              autoComplete="off"
              id="hotGripsAmount"
              value={hot_grips_amount}
              className={clientVehicleValidation.hotGripsAmount ? "is-invalid" : ""}
              onChange={(e) => {
                const { value } = e.target;
                setHotGripsAmount(value);
                setClientVehicleValidation({
                  ...clientVehicleValidation,
                  hotGripsAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                });
              }}
            />
            <small className="text-danger">{clientVehicleValidation.hotGripsAmount}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="windScreenAmount"> Wind Screen Amount </Label>
            <Input
              type="text"
              autoComplete="off"
              id="windScreenAmount"
              value={wind_screen_amount}
              className={clientVehicleValidation.windScreenAmount ? "is-invalid" : ""}
              onChange={(e) => {
                const { value } = e.target;
                setWindScreenAmount(value);
                setClientVehicleValidation({
                  ...clientVehicleValidation,
                  windScreenAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                });
              }}
            />
            <small className="text-danger">{clientVehicleValidation.windScreenAmount}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="muffsAmount"> Muffs Amount </Label>
            <Input
              type="text"
              id="muffsAmount"
              autoComplete="off"
              value={muffs_amount}
              className={clientVehicleValidation.muffsAmount ? "is-invalid" : ""}
              onChange={(e) => {
                const { value } = e.target;
                setMuffsAmount(value);
                setClientVehicleValidation({
                  ...clientVehicleValidation,
                  muffsAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                });
              }}
            />
            <small className="text-danger">{clientVehicleValidation.muffsAmount}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="legCoverAmount"> Leg Cover Amount </Label>
            <Input
              type="text"
              autoComplete="off"
              id="legCoverAmount"
              className={clientVehicleValidation.legCoverAmount ? "is-invalid" : ""}
              value={leg_cover_amount}
              onChange={(e) => {
                const { value } = e.target;
                setLegCoverAmount(value);
                setClientVehicleValidation({
                  ...clientVehicleValidation,
                  legCoverAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                });
              }}
            />
            <small className="text-danger">{clientVehicleValidation.legCoverAmount}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="mobileChargerAmount"> Mobile Charger Amount </Label>
            <Input
              type="text"
              autoComplete="off"
              id="mobileChargerAmount"
              value={mobile_charger_amount}
              className={clientVehicleValidation.mobileChargerAmount ? "is-invalid" : ""}
              onChange={(e) => {
                const { value } = e.target;
                setMobileChargerAmount(value);
                setClientVehicleValidation({
                  ...clientVehicleValidation,
                  mobileChargerAmount: !value || validateDecimalInput(value) ? "" : "Invalid amount",
                });
              }}
            />
            <small className="text-danger">{clientVehicleValidation.mobileChargerAmount}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="collectBikeFromFenchurch"> Collect Bike From Fenchurch? </Label>
            <Select
              id="collectBikeFromFenchurch"
              className="basic-single"
              classNamePrefix="select"
              value={collect_bike_from_fenchurch}
              onChange={(value) => setCollectBikeFromFenchurch(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="collect125ccFromFenchurch"> Collect 125cc From Fenchurch? </Label>
            <Select
              id="collect125ccFromFenchurch"
              className="basic-single"
              classNamePrefix="select"
              value={collect_125cc_from_fenchurch}
              onChange={(value) => setCollect125ccFromFenchurch(value)}
              options={booleanOptions}
            />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default ClientVehicle;
