import React from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import MobileInput from "../../UI/forms/MobileInput";
import Select from "react-select";
import { titleOptions } from "../AddNewCase/AddNewCaseForm";
import CustomDate from "../../UI/forms/CustomDate";
import DatePicker from "react-datepicker";

const NewInsuranceForm = () => {
  return (
    <Form>
      <Row>
        <Col sm="4">
          <FormGroup>
            <Label for="policyNumber">Policy Number</Label>
            <Input id="policyNumber" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <MobileInput label="Mobile Number" name="mobileNumber" value="" onChange={() => {}} setError={[() => {}, ""]} />
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="vehicleReg">Vehicle Registration</Label>
            <Input id="vehicleReg" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="brokerRef">Broker Reference</Label>
            <Input id="brokerRef" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="pfTitle">PH Title</Label>
            <Select id="pfTitle" className="basic-single" classNamePrefix="select" options={titleOptions} />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="phForename">PH Forename</Label>
            <Input id="phForename" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="phSurname">PH Surname</Label>
            <Input id="phSurname" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="postCode">Post Code</Label>
            <Input id="postCode" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="addressLine1">Address line 1</Label>
            <Input id="addressLine1" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="addressLine2">Address line 2</Label>
            <Input id="addressLine2" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="addressLine3">Address line 3</Label>
            <Input id="addressLine3" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="telNo1">Telephone number 1</Label>
            <Input id="telNo1" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="telNo2">Telephone number 2</Label>
            <Input id="telNo2" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="email">Email</Label>
            <Input id="email" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="phDob">PH DOB</Label>
            <br />
            <CustomDate id="pdDob" startDate={null} setStartDate={() => {}} />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="phOccupation">PH Occupation</Label>
            <Input id="phOccupation" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="pgConvictions">PH Convictions</Label>
            <Input id="pgConvictions" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="vehicleMake">Vehicle Make</Label>
            <Input id="vehicleMake" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="vehicleMake">Vehicle Make</Label>
            <Input id="vehicleMake" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="vehicleModel">Vehicle Model</Label>
            <Input id="vehicleModel" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="engineSize">Engine Size</Label>
            <Input id="engineSize" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="yearOfMake">Year of make</Label> <br />
            <DatePicker id="yearOfMake" className="form-control" selected={null} onChange={() => {}} showYearPicker dateFormat="yyyy" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="value">Value</Label>
            <Input id="value" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="insurer">Insurer</Label>
            <Input id="insurer" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="policyNumber">Policy Number</Label>
            <Input id="policyNumber" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="coverType">Cover Type</Label>
            <Input id="coverType" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="inceptionDate">Inception date</Label> <br />
            <CustomDate id="inceptionDate" startDate={null} setStartDate={() => {}} />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="compusoryExcess">Compulsory Excess</Label>
            <Input id="compusoryExcess" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="voluntaryExcess">Voluntary excess</Label>
            <Input id="voluntaryExcess" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="classExcess">Glass Excess</Label>
            <Input id="classExcess" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="driverTitle1">Add Driver 1 Title</Label>
            <Input id="driverTitle1" autoComplete="off" />
          </FormGroup>
        </Col>

        <Col sm="4">
          <FormGroup>
            <Label for="driverForname1">Add Driver 1 Forename</Label>
            <Input id="driverForname1" autoComplete="off" />
          </FormGroup>
        </Col>
      </Row>
    </Form>
  );
};

export default NewInsuranceForm;
