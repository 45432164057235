import React, { useEffect, useState } from "react";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { usePersonalInjuryContext } from "../../../context/administration/updateForm/personalInjury";
import useError from "../../../hooks/useError";
import { toDateObj, toYYYYMMDD } from "../../../utils/forms";

import { toastr } from "react-redux-toastr";
import { Link, useParams } from "react-router-dom";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import LoadingButton from "../../UI/LoadingButton";
import CustomDate from "../../UI/forms/CustomDate";
import Notes from "./Notes";
import ParamsLink from "../../UI/links/ParamsLink";
import { validatePhoneNumber } from "../../../utils/validations";

const paymentAuthriseOptions = [
  { value: "Authorise", label: "Authorise" },
  { value: "Not Authorise", label: "Not Authorise" },
];

const notAuthResOption = [
  { value: "Pending", label: "Pending" },
  { value: "Deferred", label: "Deferred" },
];

const loadPanelOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/solicitors", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedCurrentStatusOptions = [];
const loadCurrentStatusOptions = async (search) => {
  try {
    if (search && loadedCurrentStatusOptions.length) {
      const filteredOptions = loadedCurrentStatusOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/caseStatus");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedCurrentStatusOptions = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedRejectReason = [];
const loadRejectReason = async (search) => {
  try {
    if (search && loadedRejectReason.length) {
      const filteredOptions = loadedRejectReason.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/piRejectReason");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedRejectReason = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedRollbackReason = [];
const loadRollbackReason = async (search) => {
  try {
    if (search && loadedRollbackReason.length) {
      const filteredOptions = loadedRollbackReason.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/piRollbackReason");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedRollbackReason = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedAteProvidersOptions = [];
const loadAteProviders = async (search) => {
  try {
    if (search && loadedAteProvidersOptions.length) {
      const filteredOptions = loadedAteProvidersOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/ateProviders");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedAteProvidersOptions = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const loadLeadSourceOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/leadSources", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const loadBrHandlers = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/brHandlers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const PersonalInjury = () => {
  const { formId } = useParams();

  const {
    chase,
    setChase,
    brChase,
    setBrChase,
    sourcePaidStatus,
    setSourcePaidStatus,
    caseType,
    setCaseType,
    leadSource,
    setLeadSource,
    panel,
    setPanel,
    solicitorRefNo,
    setSolicitorRefNo,
    currentStatus,
    rejectReason,
    setRejectReason,
    rollbackReason,
    setRollbackReason,
    setCurrentStatus,
    possibleClawBack,
    setPossibleClawBack,
    datePaid,
    setDatePaid,
    paidStatus,
    setPaidStatus,
    buyPrice,
    setBuyPrice,
    sellingPrice,
    setSellingPrice,
    dateOpened,
    setDateOpened,
    clawbackDate,
    setClawbackDate,
    clawbackClaim,
    setClawbackClaim,
    clawbackExtensionDate,
    setClawbackExtensionDate,
    sourcePaidDate,
    setSourcePaidDate,
    sourcePaidStatusTo,
    setSourcePaidStatusTo,
    rollbackDate,
    setRollbackDate,
    signupDate,
    setSignupdate,
    retainedDate,
    setRetainedDate,
    atePaidDate,
    setAtePaidDate,
    ateProvider,
    setAteProvider,
    atePaidAmount,
    setAtePaidAmount,
    introducerPaidDate,
    setIntroducerPaidDate,
    introducerPaidAmount,
    setIntroducerPaidAmount,
    packDate,
    setPackDate,
    acceptDate,
    setAcceptDate,
    rejectDate,
    setRejectDate,
    paymentAuthorization,
    setPaymentAuthorization,
    paymentNotAuthorizedReason,
    setPaymentNotAuthorizedReason,
    PIVDOutlay,
    setPIVDOutlay,
    chaseDate,
    setChaseDate,
    chaseTime,
    setChaseTime,
    brChaseDate,
    setBrChaseDate,
    brChaseHandler,
    setBrChaseHandler,

    // -----note's values-------
    isAddNotActive,
    setIsAddNotActive,
    isAddAsReminderActive,
    setIsAddAsReminderActive,
    isSendEmailActive,
    setIsSendEmailActive,
    isSendSMSActive,
    setIsSendSMSActive,

    noteDescription,
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile,
    setSendSMSMobile,
  } = usePersonalInjuryContext();

  const [validationErrors, setValidationErrors] = useState({
    mobileNumber: "",
    clientNumber: "",
  });

  const clearNoteFields = () => {
    setIsAddNotActive(false);
    setIsAddAsReminderActive(false);
    setIsSendEmailActive(false);
    setIsSendSMSActive(false);
    setNoteDescription("");
    setAddNoteTo("");
    setAddAsReminderDate(null);
    setSendEmailTo("");
    setSendSMSTo("");
    setSendSMSMobile({
      clientNumber: "",
      mobileNumber: "",
    });
  };

  const { handleError } = useError();
  const [isUpdating, setIsUpdating] = useState(false);

  const { formData, getNotes } = useUpdateFormContext();

  const setFields = () => {
    if (!formData || !formData.personal_injury) return;

    const { personal_injury } = formData;
    setCaseType({
      seriousInjury: personal_injury.serious_injury === "Yes",
      catastrophicInjury: personal_injury.cataphoric_injury === "Yes",
    });
    setLeadSource({ value: personal_injury.lead_source_id, label: personal_injury.lead_source_name });
    setPanel({ value: personal_injury.solicitor_id, label: personal_injury.solicitor_name });
    setBrChaseHandler({ value: personal_injury.br_handler_id, label: personal_injury.br_handler_name });
    setPIVDOutlay(personal_injury.vd_layout === "Yes");
    setSolicitorRefNo(personal_injury.solicitor_reference);
    setCurrentStatus({ value: personal_injury.current_status, label: personal_injury.current_status });
    setRejectReason({ value: personal_injury.rejection_reason, label: personal_injury.rejection_reason });
    setRollbackReason({ value: personal_injury.rollback_reason, label: personal_injury.rollback_reason });
    setPossibleClawBack(+personal_injury.possible_clawback === 1);
    setDatePaid(toDateObj(personal_injury.paid_date));
    setPaidStatus(+personal_injury.is_paid === 1);
    setBuyPrice(personal_injury.buy_price);
    setSellingPrice(personal_injury.selling_price);
    setAcceptDate(toDateObj(personal_injury.accept_date));
    setPackDate(toDateObj(personal_injury.pack_date));
    setSignupdate(toDateObj(personal_injury.signup_date));
    setDateOpened(toDateObj(personal_injury.date_opened));
    setRetainedDate(toDateObj(personal_injury.retained_date));
    setClawbackDate(toDateObj(personal_injury.clawback_date));
    setRejectDate(toDateObj(personal_injury.reject_date));
    setRollbackDate(toDateObj(personal_injury.rollback_date));
    setClawbackExtensionDate(toDateObj(personal_injury.clawback_extension_date));
    setSourcePaidDate(toDateObj(personal_injury.source_paid_date));
    setSourcePaidStatus(+personal_injury.source_paid_status === 1);
    setAtePaidDate(toDateObj(personal_injury.ate_paid_date));
    setAteProvider({ value: personal_injury.ate_provider, label: personal_injury.ate_provider });
    setAtePaidAmount(personal_injury.ate_paid_amount);
    setIntroducerPaidAmount(personal_injury.introducer_paid_amount);
    setIntroducerPaidDate(toDateObj(personal_injury.introducer_paid_date));
    setPaymentAuthorization({ value: personal_injury.payment_auth, label: personal_injury.payment_auth });
    setPaymentNotAuthorizedReason({ value: personal_injury.payment_auth_option, label: personal_injury.payment_auth_option });
    setChase(personal_injury.chase_status === "Yes");
    setChaseDate(toDateObj(personal_injury.chase_date));
    setChaseTime(personal_injury.chase_time);
    setBrChase(personal_injury.br_chase_status === "Yes");
    setBrChaseDate(toDateObj(personal_injury.br_chase_date));
    setClawbackClaim(personal_injury.clawback_claim_status === "Yes");
  };

  const updateHandler = async () => {
    let isInvalid = false;
    if (!validatePhoneNumber(sendSMSMobile.clientNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "Invalid client number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "" }));
    }

    if (!validatePhoneNumber(sendSMSMobile.mobileNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Invalid mobile number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }

    if (isInvalid) return;

    try {
      setIsUpdating(true);
      const payload = {
        serious_injury: caseType.seriousInjury ? "Yes" : "No",
        cataphoric_injury: caseType.catastrophicInjury ? "Yes" : "No",
        solicitor_reference: solicitorRefNo,
        current_status: currentStatus ? currentStatus.value : "",
        possible_clawback: possibleClawBack ? 1 : 0,
        rejection_reason: rejectReason ? rejectReason.value : "",
        rollback_reason: rollbackReason ? rollbackReason.value : "",
        is_paid: paidStatus ? 1 : 0,
        paid_date: toYYYYMMDD(datePaid),
        accept_date: toYYYYMMDD(acceptDate),
        pack_date: toYYYYMMDD(packDate),
        signup_date: toYYYYMMDD(signupDate),
        date_opened: toYYYYMMDD(dateOpened),
        retained_date: toYYYYMMDD(retainedDate),
        clawback_date: toYYYYMMDD(clawbackDate),
        reject_date: toYYYYMMDD(rejectDate),
        rollback_date: toYYYYMMDD(rollbackDate),
        clawback_extension_date: toYYYYMMDD(clawbackExtensionDate),
        source_paid_date: toYYYYMMDD(sourcePaidDate),
        source_paid_status: sourcePaidStatus ? 1 : 0,
        ate_paid_date: toYYYYMMDD(atePaidDate),
        introducer_paid_date: toYYYYMMDD(introducerPaidDate),
        payment_auth: paymentAuthorization ? paymentAuthorization.value : "",
        payment_auth_option: paymentNotAuthorizedReason ? paymentNotAuthorizedReason.value : "",
        chase_status: chase ? "Yes" : "No",
        chase_date: toYYYYMMDD(chaseDate),
        chase_time: chaseTime || "",
        br_chase_status: brChase ? "Yes" : "No",
        br_chase_date: toYYYYMMDD(brChaseDate),
        vd_layout: PIVDOutlay ? "Yes" : "No",
        clawback_claim_status: clawbackClaim ? "Yes" : "No",
        note: {
          note_type: "Personal Injury",
          description: noteDescription,
          display_to_deo: addNoteTo.displayToDEO ? 1 : 0,
          display_to_client: addNoteTo.displayToClient ? 1 : 0,
          reminder_date: toYYYYMMDD(addAsReminderDate),
          send_email_source: sendEmailTo.source ? 1 : 0,
          send_email_client: sendEmailTo.client ? 1 : 0,
          send_sms_to: sendSMSTo,
          send_sms_mobile: sendSMSTo === "client" ? sendSMSMobile.clientNumber : sendSMSMobile.mobileNumber,
        },
      };

      if (leadSource) payload.lead_source_id = leadSource.value;
      if (atePaidAmount) payload.ate_paid_amount = atePaidAmount;
      if (introducerPaidAmount) payload.introducer_paid_amount = introducerPaidAmount;
      if (panel) payload.solicitor_id = panel.value;
      if (buyPrice) payload.buy_price = +buyPrice;
      if (sellingPrice) payload.selling_price = +sellingPrice;
      if (ateProvider) payload.ate_provider = ateProvider.value;
      if (brChaseHandler) payload.br_handler = brChaseHandler.value;

      await instance.put(`/case/updatePersonalInjury/${formId}`, payload);
      toastr.success("Success", "Personal Injury updated successfully");
      clearNoteFields();
      getNotes();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    setFields();
  }, [formData]);

  return (
    <Form className="px-2" onSubmit={updateHandler}>
      <div>
        <Row className="ml-2">
          <Col md={2}>
            <Input
              type="checkbox"
              id="seriousInjury"
              checked={caseType.seriousInjury}
              onChange={() =>
                setCaseType({
                  ...caseType,
                  seriousInjury: !caseType.seriousInjury,
                })
              }
            />
            <Label for="seriousInjury">Serious Injury</Label>
          </Col>

          <Col md={2}>
            <Input
              type="checkbox"
              id="catastrophicInjury"
              checked={caseType.catastrophicInjury}
              onChange={() =>
                setCaseType({
                  ...caseType,
                  catastrophicInjury: !caseType.catastrophicInjury,
                })
              }
            />
            <Label for="catastrophicInjury">Catastrophic Injury</Label>
          </Col>

          <Col md={2}>
            <div className="ml-4">
              <Input type="checkbox" id="possibleClawBack" checked={possibleClawBack} onChange={(e) => setPossibleClawBack(e.target.checked)} />
              <Label for="possibleClawBack">Possible Claw Back</Label>
            </div>
          </Col>
        </Row>
      </div>

      <Row>
        <Col md={3}>
          <FormGroup>
            <Label for="panelSelect">Panel</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="panelSelect"
              id="panelSelect"
              loadOptions={loadPanelOptions}
              additional={{
                page: 1,
              }}
              value={panel}
              debounceTimeout={300}
              onChange={(selected) => setPanel(selected)}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="solicitorRefNo">Solicitor Reference No</Label>
            <Input type="text" id="solicitorRefNo" value={solicitorRefNo} onChange={(e) => setSolicitorRefNo(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="currentStatusSelect">Current Status</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="currentStatusSelect"
              id="currentStatusSelect"
              loadOptions={loadCurrentStatusOptions}
              value={currentStatus}
              onChange={(selected) => setCurrentStatus(selected)}
            />
          </FormGroup>
        </Col>

        {currentStatus?.value === "Reject" && (
          <Col md={3}>
            <FormGroup>
              <Label for="rejectReson">Reason of Rejection</Label>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="rejectReson"
                id="rejectReson"
                loadOptions={loadRejectReason}
                value={rejectReason}
                onChange={(selected) => setRejectReason(selected)}
              />
            </FormGroup>
          </Col>
        )}

        {currentStatus?.value === "Rollback" && (
          <Col md={3}>
            <FormGroup>
              <Label for="rollbackReason">Reason of Rollback</Label>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="rollbackReason"
                id="rollbackReason"
                loadOptions={loadRollbackReason}
                value={rollbackReason}
                onChange={(selected) => setRollbackReason(selected)}
              />
            </FormGroup>
          </Col>
        )}

        <Col md={3}>
          <FormGroup>
            <Label for="leadSourceSelect">Lead Source</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="leadSourceSelect"
              id="leadSourceSelect"
              loadOptions={loadLeadSourceOptions}
              additional={{
                page: 1,
              }}
              value={leadSource}
              debounceTimeout={300}
              onChange={(selected) => setLeadSource(selected)}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="dateOpened">Date Opened</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={dateOpened} setStartDate={setDateOpened} id={"dateOpened"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="signupDate">Signup Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={signupDate} setStartDate={setSignupdate} id={"signupDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="rollbackDate">Rollback Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={rollbackDate} setStartDate={setRollbackDate} id={"rollbackDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="acceptDate">Accept Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={acceptDate} setStartDate={setAcceptDate} id={"acceptDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="rejectDate">Reject Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={rejectDate} setStartDate={setRejectDate} id={"rejectDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="packDate">Pack Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={packDate} setStartDate={setPackDate} id={"packDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="retainedDate">Retained Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={retainedDate} setStartDate={setRetainedDate} id={"retainedDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="clawbackDate">Clawback Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={clawbackDate} setStartDate={setClawbackDate} id={"clawbackDate"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="clawbackClaim" checked={clawbackClaim} onChange={(e) => setClawbackClaim(e.target.checked)} />
              <Label for="clawbackClaim">Clawback Claim</Label>
            </div>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="clawbackExtensionDate">Clawback Extension Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={clawbackExtensionDate} setStartDate={setClawbackExtensionDate} id={"clawbackExtensionDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="buyPrice">Buy Price</Label>
            <Input type="text" id="buyPrice" value={buyPrice} onChange={(e) => setBuyPrice(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="sellingPrice">Selling Price</Label>
            <Input type="text" id="sellingPrice" value={sellingPrice} onChange={(e) => setSellingPrice(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="datePaid">Date Paid</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={datePaid} setStartDate={setDatePaid} id={"datePaid"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="paidStatus" checked={paidStatus} onChange={(e) => setPaidStatus(e.target.checked)} />
              <Label for="paidStatus">Paid Status</Label>
            </div>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Col md={9} className="p-0">
              <Label for="sourcePaidDate">Source Paid Date</Label>
              <CustomDate startDate={sourcePaidDate} setStartDate={setSourcePaidDate} id={"sourcePaidDate"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="sourcePaidStatus" checked={sourcePaidStatus} onChange={(e) => setSourcePaidStatus(e.target.checked)} />
              <Label for="sourcePaidStatus">Source Paid Status</Label>
            </div>
            {sourcePaidStatus && (
              <Row className="ml-3">
                <Col md={3}>
                  <FormGroup>
                    <Input type="radio" id="sms" name="sourcePaidStatus" checked={sourcePaidStatusTo === "sms"} onClick={() => setSourcePaidStatusTo("sms")} />
                    <Label for="sms">SMS</Label>
                  </FormGroup>
                </Col>

                <Col md={3}>
                  <FormGroup>
                    <Input type="radio" id="credit" name="sourcePaidStatus" checked={sourcePaidStatusTo === "credit"} onClick={() => setSourcePaidStatusTo("credit")} />
                    <Label for="credit">Credit</Label>
                  </FormGroup>
                </Col>
              </Row>
            )}
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="introducerPaidDate">Introducer Paid Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={introducerPaidDate} setStartDate={setIntroducerPaidDate} id={"introducerPaidDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="atePaidDate">ATE Paid Date</Label>
            <Col md={9} className="p-0">
              <CustomDate startDate={atePaidDate} setStartDate={setAtePaidDate} id={"atePaidDate"} />
            </Col>
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="introducerPaidAmount">Introducer Paid Amount</Label>
            <Input type="text" id="introducerPaidAmount" value={introducerPaidAmount} onChange={(e) => setIntroducerPaidAmount(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="ateProviderSelect">ATE Provider</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="ateProviderSelect"
              id="ateProviderSelect"
              loadOptions={loadAteProviders}
              value={ateProvider}
              onChange={(selected) => setAteProvider(selected)}
            />
          </FormGroup>
        </Col>

        <Col md={3}>
          <FormGroup>
            <Label for="atePaidAmount">ATE Paid Amount</Label>
            <Input type="text" id="atePaidAmount" value={atePaidAmount} onChange={(e) => setAtePaidAmount(e.target.value)} />
          </FormGroup>
        </Col>

        <Col md={3}>
          <Label for="paymentAuthorization">Payment Authorisation</Label>
          <Select
            className="basic-single"
            classNamePrefix="select"
            name="paymentAuthorization"
            value={paymentAuthorization}
            options={paymentAuthriseOptions}
            onChange={(selectedOption) => setPaymentAuthorization(selectedOption)}
          />
        </Col>

        {paymentAuthorization?.value === "Not Authorise" && (
          <Col md={3}>
            <Label for="paymentAuthorization">Not Authorised Reason</Label>
            <Select
              className="basic-single"
              classNamePrefix="select"
              name="paymentAuthorization"
              value={paymentNotAuthorizedReason}
              options={notAuthResOption}
              onChange={(selectedOption) => setPaymentNotAuthorizedReason(selectedOption)}
            />
          </Col>
        )}
      </Row>

      <Row>
        <Col md={3} className="pl-4">
          <FormGroup>
            <div className="ml-2 mt-4">
              <Input type="checkbox" id="piVdOutlay" checked={PIVDOutlay} onChange={(e) => setPIVDOutlay(e.target.checked)} />
              <Label for="piVdOutlay">PI VD Outlay</Label>
            </div>
          </FormGroup>
        </Col>

        <Col md={3} className="pl-2 pt-4">
          <div className="ml-4">
            <Input type="checkbox" id="chase" checked={chase} onClick={(e) => setChase(e.target.checked)} />
            <Label for="chase"> Chase</Label>
          </div>
        </Col>

        {chase && (
          <>
            <Col md={3}>
              <Col md={9} className="p-0">
                <Label for="chaseDate">Chase Date</Label>
                <CustomDate startDate={chaseDate} setStartDate={setChaseDate} id={"chaseDate"} />
              </Col>
            </Col>

            <Col md={3}>
              <Col md={9} className="p-0">
                <Label for="chaseTime">Chase Time</Label>
                <Input type="time" id="chaseTime" value={chaseTime} onChange={(e) => setChaseTime(e.target.value)} />
              </Col>
            </Col>
          </>
        )}

        <Col md={3} className="pl-2 pt-4">
          <div className="ml-4">
            <Input type="checkbox" id="brChase" checked={brChase} onClick={() => setBrChase((pre) => !pre)} />
            <Label for="brChase">Br Chase</Label>
          </div>
        </Col>
        {brChase && (
          <>
            <Col md={3}>
              <Col md={9} className="p-0">
                <Label for="brChaseDate">Br Date</Label>
                <CustomDate startDate={brChaseDate} setStartDate={setBrChaseDate} id={"brChaseDate"} />
              </Col>
            </Col>
            <Col md={3}>
              <Label for="brChaseDate">Br Handler</Label>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="brHandlerSelect"
                id="brHandlerSelect"
                loadOptions={loadBrHandlers}
                additional={{
                  page: 1,
                }}
                value={brChaseHandler}
                debounceTimeout={300}
                onChange={(selected) => setBrChaseHandler(selected)}
              />
            </Col>
          </>
        )}
      </Row>

      <Notes
        noteDescription={noteDescription}
        setNoteDescription={setNoteDescription}
        sendSMSTo={sendSMSTo}
        setSendSMSTo={setSendSMSTo}
        addAsReminderDate={addAsReminderDate}
        setAddAsReminderDate={setAddAsReminderDate}
        sendEmailTo={sendEmailTo}
        setSendEmailTo={setSendEmailTo}
        addNoteTo={addNoteTo}
        setAddNoteTo={setAddNoteTo}
        sendSMSMobile={sendSMSMobile}
        setSendSMSMobile={setSendSMSMobile}
        isAddNotActive={isAddNotActive}
        setIsAddNotActive={setIsAddNotActive}
        isAddAsReminderActive={isAddAsReminderActive}
        setIsAddAsReminderActive={setIsAddAsReminderActive}
        isSendEmailActive={isSendEmailActive}
        setIsSendEmailActive={setIsSendEmailActive}
        isSendSMSActive={isSendSMSActive}
        setIsSendSMSActive={setIsSendSMSActive}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />

      <div className="d-flex justify-content-end">
        <ParamsLink to="/administration/cases" className="mr-2">
          <Button color="primary">Exit</Button>
        </ParamsLink>
        <LoadingButton onClick={updateHandler} isLoading={isUpdating}>
          Update
        </LoadingButton>
      </div>
    </Form>
  );
};

export default PersonalInjury;
