import axios from "axios";

export const fileToBase64 = (file) => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result.split(",")[1]); // Extracting the base64 data (excluding the data URI prefix)
    };

    reader.onerror = (error) => {
      reject(error);
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      reject(new Error("No file provided."));
    }
  });
};

export function isBase64Image(str) {
  return /^data:image\/(png|jpg|jpeg|gif);base64,/.test(str);
}

export const fetchAddressByPostcode = async (postCode) => {
  try {
    const response = await axios.get(`https://api.postcodes.io/postcodes/${postCode}`);
    if (response.data.status === 200) {
      const newAddress = `${response.data.result.admin_ward}, ${response.data.result.admin_district},  ${response.data.result.nhs_ha}, ${response.data.result.country}, ${response.data.result.postcode}`;
      return { address: newAddress, latitude: response.data.result.latitude, longitude: response.data.result.longitude };
    }
  } catch (error) {
    return error.response.data.status;
  }
};

export function toYYYYMMDD(dateString, reverse = false) {
  if (!dateString) return "";

  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-indexed, so we add 1
  const year = date.getFullYear();

  // Padding single-digit day and month with leading zeros
  const formattedDay = day < 10 ? "0" + day : day;
  const formattedMonth = month < 10 ? "0" + month : month;

  // if reverse is false return yyyy-mm-dd else dd-mm-yyyy
  return reverse ? `${formattedDay}-${formattedMonth}-${year}` : `${year}-${formattedMonth}-${formattedDay}`;
}

export const toDateObj = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date) ? "" : date;
};

export const toDateAndTimeObj = (dateTimeString) => {
  const dateObj = new Date(dateTimeString);
  if (isNaN(dateObj)) return ""; // Check for invalid date string

  const dateOptions = { day: '2-digit', month: '2-digit', year: 'numeric' };
  const time = dateTimeString.split(' ')[1] || ''; // Extract time part if available

  const formattedDate = dateObj.toLocaleDateString('en-GB', dateOptions).replace(/\//g, '-');
  return `${formattedDate} ${time}`;
};
