import React from "react";
import DeoDashboard from "../../components/Dashboards/DeoDashboard";
import HireSupplierDashboard from "../../components/Dashboards/HireSupplierDashboard";
import { useProfileContext } from "../../context/profile";
import DeoDashboardContextProvider from "../../context/dashboard/DeoDashboardContext";

const Dashboard = () => {
  const { profile } = useProfileContext();

  if (profile?.user_type === "DEO") {
    return (
      <DeoDashboardContextProvider>
        <DeoDashboard />
      </DeoDashboardContextProvider>
    );
  } else if (profile?.user_type === "Hire Supplier") {
    return <HireSupplierDashboard />;
  } else {
    return null;
  }
};

export default Dashboard;
