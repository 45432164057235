import React, { memo } from "react";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useUserContext } from "../../../context/security/users";
import { validateDecimalInput } from "../../../utils/validations";
import Select from "react-select";

const yesOrNoOptions = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const Additional = memo(() => {
  const {
    buyingPriceAdult,
    setBuyingPriceAdult,
    prestigeHire,
    setPrestigeHire,
    buyingPriceMinor,
    setBuyingPriceMinor,
    pcoOrTaxi,
    setPcoOrTaxi,
    rtamib,
    setRtamib,
    motorbike,
    setMotorbike,
    standardHire,
    setStandardHire,
    sourcePaymentDays,
    setSourcePaymentDays,
    isVatRegistered,
    setIsVatRegistered,
    vatRegNumber,
    setVatRegNo,

    // errors
    additionalErrors,
    setAdditionalErrors,
  } = useUserContext();

  return (
    <Form>
      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="buyingPriceAdult">Buying Price Adult</Label>
            <Input
              type="text"
              name="buyingPriceAdult"
              id="buyingPriceAdult"
              autoComplete="off"
              value={buyingPriceAdult}
              className={`${additionalErrors.buyingPriceAdult && "is-invalid"}`}
              onChange={(e) => {
                const value = e.target.value;
                setBuyingPriceAdult(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  buyingPriceAdult: value && !validateDecimalInput(value) ? "Invalid buying price!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.buyingPriceAdult}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="prestigeHire">Prestige Hire</Label>
            <Input
              type="text"
              name="prestigeHire"
              autoComplete="off"
              id="prestigeHire"
              className={`${additionalErrors.prestigeHire && "is-invalid"}`}
              value={prestigeHire}
              onChange={(e) => {
                const value = e.target.value;
                setPrestigeHire(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  prestigeHire: value && !validateDecimalInput(value) ? "Invalid prestige hire!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.prestigeHire}</small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="buyingPriceMinor">Buying Price Minor</Label>
            <Input
              type="text"
              name="buyingPriceMinor"
              autoComplete="off"
              id="buyingPriceMinor"
              className={`${additionalErrors.buyingPriceMinor && "is-invalid"}`}
              value={buyingPriceMinor}
              onChange={(e) => {
                const value = e.target.value;
                setBuyingPriceMinor(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  buyingPriceMinor: value && !validateDecimalInput(value) ? "Invalid buying price minor!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.buyingPriceMinor}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="PCOTaxi">PCO/Taxi</Label>
            <Input
              type="text"
              autoComplete="off"
              name="PCOTaxi"
              id="PCOTaxi"
              value={pcoOrTaxi}
              className={`${additionalErrors.pcoOrTaxi && "is-invalid"}`}
              onChange={(e) => {
                const value = e.target.value;
                setPcoOrTaxi(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  pcoOrTaxi: value && !validateDecimalInput(value) ? "Invalid PCO/Taxi!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.pcoOrTaxi}</small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="RTAMIB">RTAMIB</Label>
            <Input
              type="text"
              name="RTAMIB"
              autoComplete="off"
              id="RTAMIB"
              className={`${additionalErrors.rtamib && "is-invalid"}`}
              value={rtamib}
              onChange={(e) => {
                const value = e.target.value;
                setRtamib(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  rtamib: value && !validateDecimalInput(value) ? "Invalid RTAMIB!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.rtamib}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="motorBike">Motor Bike</Label>
            <Input
              type="text"
              autoComplete="off"
              name="motorBike"
              id="motorBike"
              className={`${additionalErrors.motorbike && "is-invalid"}`}
              value={motorbike}
              onChange={(e) => {
                const value = e.target.value;
                setMotorbike(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  motorbike: value && !validateDecimalInput(value) ? "Invalid motorbike!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.motorbike}</small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="standardHire">Standard Hire</Label>
            <Input
              type="text"
              autoComplete="off"
              name="standardHire"
              id="standardHire"
              value={standardHire}
              className={`${additionalErrors.standardHire && "is-invalid"}`}
              onChange={(e) => {
                const value = e.target.value;
                setStandardHire(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  standardHire: value && !validateDecimalInput(value) ? "Invalid standard hire!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.standardHire}</small>
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="sourcePaymentDays">Source Payment Days</Label>
            <Input
              type="text"
              autoComplete="off"
              name="sourcePaymentDays"
              id="sourcePaymentDays"
              className={`${additionalErrors.sourcePaymentDays && "is-invalid"}`}
              value={sourcePaymentDays}
              onChange={(e) => {
                const value = e.target.value;
                setSourcePaymentDays(value);
                setAdditionalErrors((pre) => ({
                  ...pre,
                  sourcePaymentDays: value && !validateDecimalInput(value) ? "Invalid source payment days!" : "",
                }));
              }}
            />
            <small className="text-danger">{additionalErrors.sourcePaymentDays}</small>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={6}>
          <FormGroup>
            <Label for="VATRegistered">VAT Registered</Label>

            <Select
              className="basic-single"
              classNamePrefix="select"
              name="VATRegistered"
              value={isVatRegistered ? yesOrNoOptions[0] : yesOrNoOptions[1]}
              options={yesOrNoOptions}
              onChange={(selectedOption) => setIsVatRegistered(selectedOption.value)}
            />
          </FormGroup>
        </Col>
        {isVatRegistered && (
          <Col md={6}>
            <FormGroup>
              <Label for="vatRegNo">VAT Registered No</Label>
              <Input type="text" autoComplete="off" name="vatRegNo" id="vatRegNo" value={vatRegNumber} onChange={(e) => setVatRegNo(e.target.value)} />
            </FormGroup>
          </Col>
        )}
      </Row>
    </Form>
  );
});

export default Additional;
