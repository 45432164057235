import React, { useEffect, useState } from "react";
import { Button, Col, Form, FormGroup, Input, Label, Row, Modal, ModalBody, ModalFooter, ModalHeader, Spinner } from "reactstrap";
import instance from "../../../axiosInstance";
import { toastr } from "react-redux-toastr";
import useError from "../../../hooks/useError";
import Select from "react-select";
import { titleOptions } from "../AddNewCase/AddNewCaseForm";
import ContactLink from "../../UI/links/ContactLink";

const ClaimFormHeader = ({ caseId }) => {
  // ---------------------States------------------------
  const [display_mobile_no, set_display_mobile_no] = useState();
  const [display_title, set_display_title] = useState();
  const [display_first_name, set_display_first_name] = useState();
  const [display_middle_name, set_display_middle_name] = useState();
  const [display_last_name, set_display_last_name] = useState();
  const [display_case_type, set_display_case_type] = useState(null);
  const [display_service_types, set_display_service_types] = useState([]);  
  const [display_phone_no, set_display_phone_no] = useState();
  const [mobile_no, set_mobile_no] = useState();
  const [title, set_title] = useState();
  const [first_name, set_first_name] = useState();
  const [middle_name, set_middle_name] = useState();
  const [last_name, set_last_name] = useState();
  const [case_type, set_case_type] = useState(null);
  const [service_types, set_service_types] = useState([]);  
  const [phone_no, set_phone_no] = useState();
  const [isUpdating, setIsUpdating] = useState(false);
  const { handleError } = useError();
  const [serviceTypesOptions, setServiceTypesOptions] = useState([]);
  const [caseTypes, setCaseTypes] = useState([]);
  
  // ---------------------Get and Set CaseTypes From API------------------------
  const fetchCaseTypes = async () => {
    try {
      const response = await instance.get("lookups/caseTypes");
      const options = response.data.data.map((case_type) => ({
        value: case_type,
        label: case_type,
      }));
      setCaseTypes(options);
    } catch (err) {
      handleError(err);
    }
  };

  // ---------------------Get API------------------------
  const getData = async () => {
    try {
      const response = await instance.get(`case/view/${caseId}`);
      const data = response.data.data;
      const selectedServiceTypes = data.service_types.map((service) => service.toLowerCase().replace(/\s/g, ""));

      set_display_mobile_no(data.mobile_no);
      set_display_title(data.title);
      set_display_first_name(data.first_name);
      set_display_middle_name(data.middle_name);
      set_display_last_name(data.last_name);
      set_display_phone_no(data.phone_no)
      set_display_case_type(data.case_type);
      set_display_service_types(selectedServiceTypes);

      set_title({label: data.title, value: data.title});
      set_mobile_no(data.mobile_no);
      set_first_name(data.first_name);
      set_middle_name(data.middle_name);
      set_last_name(data.last_name);
      set_phone_no(data.phone_no)
      set_case_type({label: data.case_type, value: data.case_type})
      set_service_types(selectedServiceTypes);

    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getData();
    fetchCaseTypes();
  }, []);

  // ---------------------Edit Case Popup Part------------------------
  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  const editButtonHandler = () => {
    setIsModalOpen(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    switch (name) {
      case "mobile_no":
        set_mobile_no(value);
        break;
      case "first_name":
        set_first_name(value);
        break;
      case "middle_name":
        set_middle_name(value);
        break;
      case "last_name":
        set_last_name(value);
        break;
      case "phone_no":
        set_phone_no(value);
        break;
      default:
        break;
    }
  };

  const handleCheckboxChange = (e) => {
    const { id, checked } = e.target;
    const updatedServiceTypes = checked ? [...service_types, id] : service_types.filter((type) => type !== id);
    set_service_types(updatedServiceTypes);
  };

  const handleCaseTypeChange = (selectedOption) => {
    set_case_type(selectedOption);
    set_service_types([]);
  };

  const handleTitleChange = (selectedOption) => {
    set_title(selectedOption);
  };
  

  useEffect(() => {
    const handleServiceTypes = () => {
      switch (case_type && case_type.label) {
        case "RTA Driver":
        case "RTA Motorcycle":
        case "RTA Portal":
        case "RTA Non Driver":
          setServiceTypesOptions([
            { id: "personalinjury", label: "Personal Injury" },
            { id: "hire", label: "Hire" },
            { id: "repair", label: "Repair" },
            { id: "storage", label: "Storage" },
            { id: "recovery", label: "Recovery" },
            { id: "inspection", label: "Inspection" },
            { id: "outlay", label: "Outlay" },
          ]);
          break;
        case "RTAMIB":
          setServiceTypesOptions([
            { id: "personalinjury", label: "Personal Injury" },
            { id: "hire", label: "Hire" },
            { id: "repair", label: "Repair" },
          ]);
          break;
        default:
          setServiceTypesOptions([
            { id: "personalinjury", label: "Personal Injury" },
          ]);
      }
    };
    handleServiceTypes();
  }, [case_type]);

  const handleSubmit = async () => {

    if(service_types.length <= 0){
      toastr.error("Error", "At least one service type is required");
      return;
    }

    try {
      setIsUpdating(true);
      const updatedFormData = {
        mobile_no,
        title: title?.label,
        first_name,
        middle_name,
        last_name,
        case_type: case_type?.label,
        phone_no,
        service_type_personal_injury: service_types.includes("personalinjury") ? 1 : 0,
        service_type_hire: service_types.includes("hire") ? 1 : 0,
        service_type_repair: service_types.includes("repair") ? 1 : 0,
        service_type_storage: service_types.includes("storage") ? 1 : 0,
        service_type_recovery: service_types.includes("recovery") ? 1 : 0,
        service_type_inspection: service_types.includes("inspection") ? 1 : 0,
        service_type_outlay: service_types.includes("outlay") ? 1 : 0,
      };

      await instance.put(`case/edit/${caseId}`, updatedFormData);
      getData();
      toastr.success("Success", "Case Updated Successfully");
      toggleModal();
    } catch (err) {
      handleError(err);
    } finally {
      setIsUpdating(false);
    }
  };
  // ---------------------Edit Case Part End------------------------

  return (
    <div className="p-4">
      <div className="d-flex justify-content-end">
        <Button color="primary" onClick={editButtonHandler}>
          Edit
        </Button>
      </div>

      <Modal isOpen={isModalOpen} centered toggle={toggleModal}>
        <ModalHeader>Update the Form</ModalHeader>

        {/* -------------------------------------------------------- */}
        <Form onSubmit={handleSubmit} className="m-4">
          <ModalBody>
            <Row>
              <Col md={6}>

                <FormGroup>
                  <Label for="mobilePhone">Mobile Phone</Label>
                  <Input type="text" id="mobilePhone" name="mobile_no" value={mobile_no} onChange={handleChange} />
                </FormGroup>

                <FormGroup>
                  <Label htmlFor="title">Title</Label>
                  <Select
                    className={`basic-single`}
                    classNamePrefix="select"
                    id="title"
                    name="title"
                    value={title}
                    options={titleOptions}
                    onChange={handleTitleChange}
                  />
                </FormGroup>

                <FormGroup>
                  <Label for="firstName">First Name</Label>
                  <Input type="text" id="firstName" name="first_name" value={first_name} onChange={handleChange} />
                </FormGroup>

                <FormGroup>
                  <Label for="middleName">Middle Name</Label>
                  <Input type="text" id="middleName" name="middle_name" value={middle_name} onChange={handleChange} />
                </FormGroup>

                <FormGroup>
                  <Label for="lastName">Last Name</Label>
                  <Input type="text" id="lastName" name="last_name" value={last_name} onChange={handleChange} />
                </FormGroup>
              </Col>

              <Col md={6}>

                <FormGroup>
                  <Label for="homeTelephone">Home Telephone</Label> 
                  <Input type="text" id="homeTelephone" name="phone_no" value={phone_no} onChange={handleChange}/>
                </FormGroup>

                <FormGroup>
                  <Label for="caseType">Case Type</Label>
                  <Select
                    id="caseType"
                    classNamePrefix="select"
                    className={`basic-single`}
                    value={case_type}
                    onChange={handleCaseTypeChange}
                    isSearchable={true}
                    options={caseTypes}
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Service Types</Label>
                  <div className="border p-2">
                    <Row className="ml-2">
                      {serviceTypesOptions.map((service) => (
                        <Col md={12} key={service.id}>
                          <Label check>
                            <Input type="checkbox" id={service.id} name={service.id} checked={service_types.includes(service.id)} onChange={handleCheckboxChange} />
                            {service.label}
                          </Label>
                        </Col>
                      ))}
                    </Row>
                  </div>
                </FormGroup>
                
              </Col>

            </Row>
          </ModalBody>

          <ModalFooter>
            <Button color="primary" onClick={toggleModal} outline>
              Cancel
            </Button>
            <Button color="primary" onClick={handleSubmit} disabled={isUpdating}>
              {isUpdating && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
              Update
            </Button>
          </ModalFooter>
        </Form>
        {/* -------------------------------------------------------- */}
      </Modal>

      <Row>
        <Col md={6}>
          <p className="m-0">{`NASL-${caseId}`}</p>
        </Col>
        <Col md={6}>
          <p className="m-0">Client: {`${display_title} ${display_first_name} ${display_middle_name} ${display_last_name}`}</p>
        </Col>
        <Col md={6}>
          <p className="m-0">Mobile Phone: {<ContactLink>{display_mobile_no}</ContactLink>} </p>
        </Col>
        <Col md={6}>
          <p className="m-0">Home Telephone: {<ContactLink>{display_phone_no}</ContactLink>}</p>
        </Col>
        <Col md={6}>
          <p className="m-0">Case Type: {display_case_type}</p>
        </Col>
        <Col md={6}>
          <p className="m-0">Service Types: {display_service_types && display_service_types.join(", ")}</p>
        </Col>
      </Row>
    </div>
  );
};

export default ClaimFormHeader;