import React, { memo } from "react";

import DatePicker from "react-datepicker";

function range(start, stop, step = 1) {
  const result = [];
  for (let i = start; i < stop; i += step) {
    result.push(i);
  }
  return result;
}

const getYear = (date) => date.getFullYear();
const getMonth = (date) => date.getMonth();

const CustomDate = memo(({ startDate = null, setStartDate, id = "", className = "" }) => {
  const years = range(1900, getYear(new Date()) + 1, 1);
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

  return (
    <DatePicker
      todayButton="Today"
      dateFormat="dd-MM-yyyy"
      id={id}
      className={`form-control ${className}`}
      placeholderText="Select a date"
      renderCustomHeader={({ date, changeYear, changeMonth }) => (
        <div
          style={{
            margin: 10,
            display: "flex",
            justifyContent: "center",
            gap: "5px",
          }}
        >
          <select value={getYear(date)} onChange={({ target: { value } }) => changeYear(value)} className="form-control">
            {years.map((option) => (
              <option key={option} value={option} className="form-control">
                {option}
              </option>
            ))}
          </select>

          <select value={months[getMonth(date)]} onChange={({ target: { value } }) => changeMonth(months.indexOf(value))} className="form-control">
            {months.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </select>
        </div>
      )}
      selected={startDate}
      onChange={(date) => setStartDate(date)}
    />
  );
});

export default CustomDate;
