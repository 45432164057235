import React, { memo } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import DashboardCard from "./DashboardCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBriefcaseMedical } from "@fortawesome/free-solid-svg-icons";

const DailyStatistics = memo(() => {
  return (
    <Card>
      <CardBody className="pb-0">
        <CardTitle tag="h5">Daily Statistics</CardTitle>

        <Row>
          <Col sm="6">
            <DashboardCard title="Personal Injury" value="3" className="border" bottomMargin="mb-0" icon={<FontAwesomeIcon size="lg" icon={faBriefcaseMedical} />} />
          </Col>
          <Col sm="6">
            <DashboardCard title="Repair Cases" value="1" className="border" bottomMargin="mb-0" icon={<FontAwesomeIcon size="lg" icon={faBriefcaseMedical} />} />
          </Col>
          <Col sm="6">
            <DashboardCard title="Hire Cases" value="1" className="border" bottomMargin="mb-0" icon={<FontAwesomeIcon size="lg" icon={faBriefcaseMedical} />} />
          </Col>
          <Col sm="6">
            <DashboardCard title="Cases" value="4" className="border" bottomMargin="mb-0" icon={<FontAwesomeIcon size="lg" icon={faBriefcaseMedical} />} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

export default DailyStatistics;
