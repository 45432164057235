import React from "react";
import { Breadcrumb, BreadcrumbItem, Card, CardBody, Container } from "reactstrap";
import { Header, HeaderTitle } from "../../components";
import NewInsuranceForm from "../../components/Administration/NewInsurance/NewInsuranceForm";

const NewInsurance = () => {
  return (
    <Container fluid>
      <Header>
        <HeaderTitle>New Insurance</HeaderTitle>

        <Breadcrumb>
          <BreadcrumbItem active>Administration</BreadcrumbItem>
          <BreadcrumbItem active>New Insurance</BreadcrumbItem>
        </Breadcrumb>
      </Header>

      <Card>
        <CardBody>
          <NewInsuranceForm />
        </CardBody>
      </Card>
    </Container>
  );
};

export default NewInsurance;
