import React, { memo, useState } from "react";
import { Card, CardBody, CardTitle, Col, Progress, Row } from "reactstrap";
import MonthPicker from "../UI/forms/MonthPicker";

const MONTHS = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

const MonthlyProgess = memo(({ title, value, total }) => {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-between align-items-center">
        <h6>{title}</h6>
        <h4>{value}</h4>
      </div>
      <Progress color="primary" value={total} />
    </div>
  );
});

const MonthlyProgessFeed = memo(({ pi, hire, repair, startDate, setStartDate }) => {
  const selectedMonth = MONTHS[startDate?.getMonth() || 0];
  const selectedYear = startDate?.getFullYear() || 2002;
  const totalValue = pi + hire + repair;

  const personalInjuryPercentage = (pi / totalValue) * 100;
  const repairCasesPercentage = (repair / totalValue) * 100;
  const hireCasesPercentage = (hire / totalValue) * 100;

  return (
    <Card className="border">
      <CardBody>
        <div className="d-flex justify-content-between align-items-center">
          <CardTitle tag="h5">
            {selectedMonth}, {selectedYear} <span className="text-muted">Monthly Records</span>
          </CardTitle>
          <div className="w-120">
            <MonthPicker startDate={startDate} setStartDate={setStartDate} />
          </div>
        </div>

        <div className="mt-2">
          <MonthlyProgess title="Personal Injury" value={pi} total={personalInjuryPercentage} />
          <MonthlyProgess title="Repair Cases" value={repair} total={repairCasesPercentage} />
          <MonthlyProgess title="Hire Cases" value={hire} total={hireCasesPercentage} />
        </div>
      </CardBody>
    </Card>
  );
});

const TopSourceItem = memo(({ title, value, pi, hire, repair }) => {
  return (
    <Card className="border">
      <CardBody>
        <Row>
          <Col sm="6">
            <h6>{title}</h6>
            <h4>{value}</h4>
          </Col>

          <Col sm="6">
            <Row>
              <Col sm="4" className="border-right">
                <h6>PI</h6>
                <h4>{pi}</h4>
              </Col>
              <Col sm="4" className="border-right">
                <h6>Hire</h6>
                <h4>{hire}</h4>
              </Col>
              <Col sm="4">
                <h6>Repair</h6>
                <h4>{repair}</h4>
              </Col>
            </Row>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
});

const TopTenSources = memo(({ startDate }) => {
  const selectedMonth = MONTHS[startDate?.getMonth() || 0];
  const selectedYear = startDate?.getFullYear() || 2002;

  return (
    <Card className="border">
      <CardBody>
        <CardTitle tag="h5">
          Top 10 sources - {selectedMonth}, {selectedYear}
        </CardTitle>

        <TopSourceItem title="Total Claims Solutions" value="3" pi="89" hire="58" repair="25" />
        <TopSourceItem title="admin" value="3" pi="89" hire="58" repair="25" />
      </CardBody>
    </Card>
  );
});

const MonthlyStatistics = memo(() => {
  const [startDate, setStartDate] = useState(new Date());

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Monthly Statistics</CardTitle>

        <MonthlyProgessFeed pi={89} hire={58} repair={25} startDate={startDate} setStartDate={setStartDate} />

        <TopTenSources startDate={startDate} />
      </CardBody>
    </Card>
  );
});

export default MonthlyStatistics;
