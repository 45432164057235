import React, { useEffect } from "react";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import useError from "../../../hooks/useError";
import { fetchAddressByPostcode, toDateObj } from "../../../utils/forms";
import { validateAlphaNumeric, validateEmail } from "../../../utils/validations";
import CustomDate from "../../UI/forms/CustomDate";
import MobileInput from "../../UI/forms/MobileInput";
import ContactInput from "../../UI/forms/ContactInput";
import { useDebounce } from "use-debounce";

export const booleanOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

const ClientDetails = ({ caseId, caseType }) => {
  const { handleError } = useError();

  const {
    occupant_type,
    setOccupantType,
    phone_no,
    setPhoneNo,
    emergency_no,
    setEmergencyNo,
    postal_address,
    setPostalAddress,
    postal_code,
    setPostalCode,
    email_address,
    setEmailAddress,
    date_of_birth,
    setDateOfBirth,
    ni_number,
    setNiNumber,
    preferred_language,
    setPreferredLanguage,
    translator_name,
    setTranslatorName,
    translator_phone,
    setTranslatorPhone,
    translator_relation,
    setTranslatorRelationship,
    translator_password,
    setTranslatorPassword,
    employment_status,
    setEmploymentStatus,
    employment_status_remarks,
    setEmploymentStatusRemarks,
    tenancy_date,
    setTenancyStartDate,
    tenancy_name,
    setTenancyName,
    occupant_relation,
    setOccupantRelation,
    tenancy_agreement_available,
    setTenancyAgreementAvailable,
    arrears_with_landlord,
    setArrearsWithLandlord,
    clientDetailsValidation,
    setClientDetailsValidation,
  } = useClaimFormContext();

  const [debouncedPostcode] = useDebounce(postal_code, 500);

  //fetching Combos/ Dropdown Options
  let fetchedOccupantTypesOptions = [];
  const fetchOccupantTypesOptions = async (search) => {
    try {
      if (search && fetchedOccupantTypesOptions.length) {
        const filteredOptions = fetchedOccupantTypesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        return {
          options: filteredOptions,
          hasMore: false,
        };
      }

      const res = await instance.get("/lookups/occupantTypes");
      const { data } = res.data;
      const options = data.map((status) => ({
        value: status,
        label: status,
      }));

      fetchedOccupantTypesOptions = options;

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  let fetchedPreferredLanguagesOptions = [];
  const fetchPreferredLanguagesOptions = async (search) => {
    try {
      if (search && fetchedPreferredLanguagesOptions.length) {
        const filteredOptions = fetchedPreferredLanguagesOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        return {
          options: filteredOptions,
          hasMore: false,
        };
      }

      const res = await instance.get("/lookups/preferredLanguages");
      const { data } = res.data;
      const options = data.map((status) => ({
        value: status,
        label: status,
      }));

      fetchedPreferredLanguagesOptions = options;

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  let fetchedEmploymentStatusOptions = [];
  const fetchEmploymentStatusOptions = async (search) => {
    try {
      if (search && fetchedEmploymentStatusOptions.length) {
        const filteredOptions = fetchedEmploymentStatusOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        return {
          options: filteredOptions,
          hasMore: false,
        };
      }

      const res = await instance.get("/lookups/employmentStatus");
      const { data } = res.data;
      const options = data.map((status) => ({
        value: status,
        label: status,
      }));

      fetchedEmploymentStatusOptions = options;

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };

  // ----------------------------- Fetching Data ------------------------------
  const getData = async () => {
    try {
      const response = await instance.get(`case/view/${caseId}`);
      const data = response.data.data.client_detail;

      const selectedTenancyAgreementAvailable = booleanOptions.find((option) => option.label === data.tenancy_agreement_available);
      const selectedArrearsWithLandlord = booleanOptions.find((option) => option.label === data.arrears_with_landlord);

      if (data) {
        setOccupantType({ label: data.occupant_type, value: data.occupant_type });
        setPhoneNo(data.phone_no);
        setEmergencyNo(data.emergency_no);
        setPostalAddress(data.postal_address);
        setPostalCode(data.postal_code);
        setEmailAddress(data.email_address);
        setDateOfBirth(toDateObj(data.date_of_birth));
        setNiNumber(data.ni_number);
        setPreferredLanguage({ label: data.preferred_language, value: data.preferred_language });
        setTranslatorName(data.translator_name);
        setTranslatorPhone(data.translator_phone);
        setTranslatorRelationship(data.translator_relation);
        setTranslatorPassword(data.translator_password);
        setEmploymentStatus({ label: data.employment_status, value: data.employment_status });
        setEmploymentStatusRemarks(data.employment_status_remarks);
        setTenancyStartDate(toDateObj(data.tenancy_date));
        setTenancyName(data.tenancy_name);
        setOccupantRelation(data.occupant_relation);
        setTenancyAgreementAvailable(selectedTenancyAgreementAvailable);
        setArrearsWithLandlord(selectedArrearsWithLandlord);
      }
    } catch (err) {
      handleError(err);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const fetchAddress = async (newPostCode) => {
    const newAddress = await fetchAddressByPostcode(newPostCode);

    if (newAddress !== 404) {
      setPostalAddress(newAddress.address);
    }
  };

  useEffect(() => {
    if (debouncedPostcode?.trim() !== "") {
      fetchAddress(debouncedPostcode);
    }
  }, [debouncedPostcode]);

  return (
    <Form>
      <h4>Client Details</h4>

      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="occupantType">Occupant Type</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable
              name="occupantType"
              loadOptions={fetchOccupantTypesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={occupant_type}
              onChange={(value) => setOccupantType(value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <ContactInput name="phoneNo" label="Telephone" value={phone_no} onChange={setPhoneNo} isInvalid={clientDetailsValidation.telephone} setError={[setClientDetailsValidation, "telephone"]} />
        </Col>
        <Col md={4}>
          <ContactInput
            name="emergencyNo"
            label="Emergency Contact No"
            value={emergency_no}
            onChange={setEmergencyNo}
            isInvalid={clientDetailsValidation.emergencyContactNo}
            setError={[setClientDetailsValidation, "emergencyContactNo"]}
          />
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="postalCode">Post Code</Label>
            <Input
              type="text"
              id="postalCode"
              name="postalCode"
              className={clientDetailsValidation.postalCode ? "is-invalid" : ""}
              value={postal_code}
              onChange={(e) => {
                const { value } = e.target;
                setPostalCode(value);
                setClientDetailsValidation({ ...clientDetailsValidation, postalCode: !value || validateAlphaNumeric(value) ? "" : "Invalid postal code!" });
              }}
            />
            <small className="text-danger">{clientDetailsValidation.postalCode}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="postalAddress">Address</Label>
            <Input type="text" id="postalAddress" name="postalAddress" value={postal_address} onChange={(e) => setPostalAddress(e.target.value)} autoComplete="off" />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="emailAddress">Email Address</Label>
            <Input
              type="text"
              id="emailAddress"
              name="emailAddress"
              autoComplete="off"
              className={clientDetailsValidation.emailAddress ? "is-invalid" : ""}
              value={email_address}
              onChange={(e) => {
                const { value } = e.target;
                setEmailAddress(value);
                setClientDetailsValidation({ ...clientDetailsValidation, emailAddress: !value || validateEmail(value) ? "" : "Invalid email address!" });
              }}
            />
            <small className="text-danger">{clientDetailsValidation.emailAddress}</small>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="dateOfBirth">Date Of Birth</Label>
            <Col md={6} className="pl-0">
              <CustomDate startDate={date_of_birth} setStartDate={setDateOfBirth} id="dateOfBirth" />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="niNumber">Ni Number</Label>
            <Input type="text" id="niNumber" autoComplete="off" name="niNumber" value={ni_number} onChange={(e) => setNiNumber(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="preferredLanguage">Preferred Language</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable
              name="preferredLanguage"
              loadOptions={fetchPreferredLanguagesOptions}
              additional={{
                page: 1,
              }}
              debounceTimeout={300}
              value={preferred_language}
              onChange={(value) => setPreferredLanguage(value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="translatorName"> Lit Friend/Translator Name </Label>
            <Input type="text" id="translatorName" autoComplete="off" name="translatorName" value={translator_name} onChange={(e) => setTranslatorName(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <ContactInput
            name="translatorPhone"
            label=" Lit Friend/Translator Telephone"
            value={translator_phone}
            onChange={setTranslatorPhone}
            isInvalid={clientDetailsValidation.translatorTelephone}
            setError={[setClientDetailsValidation, "translatorTelephone"]}
          />
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="translatorRelationship"> Lit Friend/Translator Relationship</Label>
            <Input type="text" id="translatorRelationship" autoComplete="off" name="translatorRelationship" value={translator_relation} onChange={(e) => setTranslatorRelationship(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="translatorPassword"> Lit Friend/Translator Password</Label>
            <Input type="password" id="translatorPassword" autoComplete="off" name="translatorPassword" value={translator_password} onChange={(e) => setTranslatorPassword(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="employmentStatus"> Employment Status </Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="employmentStatus"
              id="employmentStatus"
              loadOptions={fetchEmploymentStatusOptions}
              value={employment_status}
              onChange={(value) => setEmploymentStatus(value)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="employmentStatusRemarks"> Occupation Type </Label>
            <Input
              type="text"
              id="employmentStatusRemarks"
              autoComplete="off"
              name="employmentStatusRemarks"
              value={employment_status_remarks}
              onChange={(e) => setEmploymentStatusRemarks(e.target.value)}
            />
          </FormGroup>
        </Col>
        {caseType === "Housing Disrepair" && (
          <>
            <Col md={4}>
              <FormGroup>
                <Label for="tenancyStartDate">Tanancy Start Date</Label>
                <Col md={6} className="pl-0">
                  <CustomDate startDate={tenancy_date} setStartDate={setTenancyStartDate} id="tenancy_date" />
                </Col>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="tenancyName"> Tanancy Name </Label>
                <Input type="text" autoComplete="off" id="tenancyName" name="tenancyName" value={tenancy_name} onChange={(e) => setTenancyName(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="occupantRelation"> Occupant Relation </Label>
                <Input type="text" autoComplete="off" id="occupantRelation" name="occupantRelation" value={occupant_relation} onChange={(e) => setOccupantRelation(e.target.value)} />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="tenancyAgreementAvailable">Tenancy Agreement Available?</Label>
                <Select
                  id="tenancyAgreementAvailable"
                  name="tenancyAgreementAvailable"
                  className="basic-single"
                  classNamePrefix="select"
                  value={tenancy_agreement_available}
                  onChange={(value) => setTenancyAgreementAvailable(value)}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label for="arrearsWithLandlord">Rent Arrears With Landlord?</Label>
                <Select
                  id="arrearsWithLandlord"
                  name="arrearsWithLandlord"
                  className="basic-single"
                  classNamePrefix="select"
                  value={arrears_with_landlord}
                  onChange={(value) => setArrearsWithLandlord(value)}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
          </>
        )}
      </Row>
    </Form>
  );
};

export default ClientDetails;