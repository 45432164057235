import React, { memo } from "react";

import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";

const DashboardCard = memo(({ title, value, icon, className = "", titleClass = "", valueClass = "", bottomMargin = "mb-3" }) => {
  return (
    <Card className={className}>
      <CardBody>
        <Row>
          <Col className="mt-0">
            <CardTitle tag="h5" className={titleClass}>
              {title}
            </CardTitle>
          </Col>

          {icon && (
            <Col className="col-auto">
              <div className="avatar">
                <div className="avatar-title rounded-circle bg-primary-dark">{icon}</div>
              </div>
            </Col>
          )}
        </Row>
        <h1 className={`display-5 mt-1 ${bottomMargin} ${valueClass}`}>{value}</h1>
      </CardBody>
    </Card>
  );
});

export default DashboardCard;
