import React, { memo, useEffect, useState } from "react";

import { v4 as uuid } from "uuid";

import { Timestamp, arrayUnion, doc, getDoc, onSnapshot, setDoc, updateDoc } from "firebase/firestore";
import { Button, Card, Col, Form, Input, InputGroup, InputGroupAddon, Media, Row } from "reactstrap";

import avatar1 from "../../assets/img/avatars/avatar.jpg";

import { AsyncPaginate } from "react-select-async-paginate";
import instance from "../../axiosInstance";
import { useProfileContext } from "../../context/profile";
import { db } from "../../firebase";
import useError from "../../hooks/useError";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRocketchat } from "@fortawesome/free-brands-svg-icons";
import moment from "moment";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";

function timestampToTime(timestamp) {
  const milliseconds = timestamp.seconds * 1000 + Math.round(timestamp.nanoseconds / 1e6);
  const date = moment(milliseconds);

  return date.format("h:mm A");
}

const loadUsers = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/user/lists", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((user) => ({
      value: user.user_id,
      label: user.full_name,
      image: user.user_image,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const ChatMessage = memo(({ position, avatar, name, children, time }) => (
  <div className={`chat-message-${position} pb-4`}>
    <div>
      <img src={avatar} loading="lazy" className="rounded-circle mr-1" alt={name} width="30" height="30" />
    </div>
    <div className={`chat-text-${position} flex-shrink-1 rounded pt-2 px-3 ${position === "right" ? "mr-3" : "ml-3"}`}>
      {children}
      <div className={`text-muted small text-nowrap text-${position}`}>{time}</div>
    </div>
  </div>
));

const Chat = memo(() => {
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState("");
  const { handleError } = useError();

  const [selectedUser, setSelectedUser] = useState(null);

  const { profile } = useProfileContext();

  const getConcatedId = () => {
    if (!selectedUser) return;

    const userId = +selectedUser.value;
    const myId = +profile.user_id;

    return userId < myId ? `${userId},${myId}` : `${myId},${userId}`;
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    try {
      if (!selectedUser || !message.trim()) return;

      const res = await getDoc(doc(db, "chats", getConcatedId()));
      if (res.exists()) {
        await updateDoc(doc(db, "chats", getConcatedId()), {
          messages: arrayUnion({
            id: uuid(),
            text: message,
            senderId: profile.user_id,
            date: Timestamp.now(),
          }),
        });
      } else {
        await setDoc(doc(db, "chats", getConcatedId()), {
          messages: arrayUnion({
            id: uuid(),
            text: message,
            senderId: profile.user_id,
            date: Timestamp.now(),
          }),
        });
      }
      setMessage("");
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };

  const getMessages = async () => {
    try {
      const unSub = onSnapshot(doc(db, "chats", getConcatedId()), (doc) => {
        if (doc.exists()) {
          setMessages(doc.data().messages);
          console.log(doc.data().messages);
        } else {
          setMessages([]);
        }
      });

      return () => {
        unSub();
      };
    } catch (error) {
      console.log(error);
      handleError(error);
    }
  };

  useEffect(() => {
    if (selectedUser) {
      getMessages();
    }
  }, [selectedUser?.value]);

  return (
    <Card>
      <Row noGutters>
        <Col>
          <div className="py-2 px-4 border-bottom d-none d-block">
            <Media className="align-items-center py-1">
              <>
                <div className="position-relative">
                  {selectedUser && <img src={selectedUser?.image} className="rounded-circle mr-1" alt={selectedUser?.label} width="40" height="40" loading="lazy" />}
                </div>
                <Media body className="pl-3">
                  <strong>{selectedUser?.label}</strong>
                </Media>
              </>
              <div className="w-180">
                <AsyncPaginate
                  className="basic-single"
                  classNamePrefix="select"
                  placeholder="Select User"
                  isSearchable={true}
                  loadOptions={loadUsers}
                  additional={{
                    page: 1,
                  }}
                  value={selectedUser}
                  debounceTimeout={300}
                  onChange={(selected) => setSelectedUser(selected)}
                />
              </div>
            </Media>
          </div>

          <div className="position-relative">
            {messages.length ? (
              <div className="chat-messages p-4 h-270">
                {messages.map((message) => (
                  <ChatMessage
                    key={message.id}
                    position={message.senderId === profile?.user_id ? "right" : "left"}
                    name={message.senderId === profile?.user_id ? "You" : "Peter"}
                    avatar={message.senderId === profile?.user_id ? profile.href_user_image : avatar1}
                    time={timestampToTime(message.date)}
                  >
                    {message.text}
                  </ChatMessage>
                ))}
              </div>
            ) : (
              <div className="p-4 d-flex justify-content-center align-items-center h-270">
                <h4>
                  <FontAwesomeIcon icon={faRocketchat} size="lg" /> No Messages!
                </h4>
              </div>
            )}
          </div>

          <div className="flex-grow-0 py-3 px-4 border-top">
            <Form onSubmit={sendMessage}>
              <InputGroup>
                <Input type="text" placeholder="Start typing" value={message} onChange={(e) => setMessage(e.target.value)} />
                <InputGroupAddon addonType="append">
                  <Button color="primary">
                    <FontAwesomeIcon icon={faPaperPlane} />
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            </Form>
          </div>
        </Col>
      </Row>
    </Card>
  );
});

export default Chat;
