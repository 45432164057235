import React, { createContext, useContext, useState } from "react";
import instance from "../../../axiosInstance";
import useError from "../../../hooks/useError";
import { useParams } from "react-router-dom";

export const updateFormContext = createContext({});
export const useUpdateFormContext = () => useContext(updateFormContext);

const UpdateFormContextProiver = ({ children }) => {
  const { handleError } = useError(); // error handler hook

  const [activeTab, setActiveTab] = useState("");
  const [id, setId] = useState("");
  const [clientName, setClientName] = useState("");
  const [caseType, setCaseType] = useState("");
  const [enteredBy, setEnteredBy] = useState("");
  const [vehicleRegistrationNo, setVehicleRegistrationNo] = useState("");
  const [fileHandler, setFileHandler] = useState(null);
  const [hireFileHandler, setHireFileHandler] = useState(null);

  const [formData, setFormData] = useState(null);

  const [notes, setNotes] = useState([]);
  const [isNotesGetting, setIsNotesGetting] = useState(false);
  const { formId } = useParams();

  const getNotes = async () => {
    try {
      setIsNotesGetting(true);
      const response = await instance.get(`/case/getNotes/${formId}`);
      const data = response.data.data.data;
      setNotes(data);
    } catch (error) {
      handleError(error);
    } finally {
      setIsNotesGetting(false);
    }
  };

  const values = {
    activeTab,
    setActiveTab,
    id,
    setId,
    clientName,
    setClientName,
    caseType,
    setCaseType,
    enteredBy,
    setEnteredBy,
    vehicleRegistrationNo,
    setVehicleRegistrationNo,
    fileHandler,
    setFileHandler,
    hireFileHandler,
    setHireFileHandler,
    formData,
    setFormData,
    notes,
    getNotes,
    isNotesGetting,
  };

  return <updateFormContext.Provider value={values}>{children}</updateFormContext.Provider>;
};

export default UpdateFormContextProiver;
