import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { toastr } from "react-redux-toastr";
import { Link, useParams } from "react-router-dom";
import Select from "react-select";
import { AsyncPaginate } from "react-select-async-paginate";
import { Button, Col, Form, FormGroup, Input, Label, Row } from "reactstrap";
import instance from "../../../axiosInstance";
import { useUpdateFormContext } from "../../../context/administration/updateForm";
import { useHireContext } from "../../../context/administration/updateForm/hire";
import useError from "../../../hooks/useError";
import { toDateObj, toYYYYMMDD } from "../../../utils/forms";
import CustomDate from "../../UI/forms/CustomDate";
import Notes from "./Notes";
import LoadingButton from "../../UI/LoadingButton";
import ParamsLink from "../../UI/links/ParamsLink";
import { validatePhoneNumber } from "../../../utils/validations";

const yesOrNoOptions = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

let loadedHireApprovedOption = [];
const loadHireApprovedOption = async (search) => {
  try {
    if (search && loadedHireApprovedOption.length) {
      const filteredOptions = loadedHireApprovedOption.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/hireApproved");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedHireApprovedOption = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedHireCategories = [];
const loadHireCategories = async (search) => {
  try {
    if (search && loadedHireCategories.length) {
      const filteredOptions = loadedHireCategories.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/hireCategory");
    const { data } = res.data;
    const options = data.map((category) => ({
      value: category,
      label: category,
    }));

    loadedHireCategories = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedHireStatus = [];
const loadHireStatus = async (search) => {
  try {
    if (search && loadedHireStatus.length) {
      const filteredOptions = loadedHireStatus.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/hireStatus");
    const { data } = res.data;
    const options = data.map((category) => ({
      value: category,
      label: category,
    }));

    loadedHireStatus = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const loadLeadSourceOptions = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/leadSources", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const loadHireSuppliers = async (search, loadedOptions, { page }) => {
  try {
    const res = await instance.get("/lookups/hireSuppliers", {
      params: { search, page, limit: 10 },
    });

    const { data } = res.data;
    const options = data.data.map((role) => ({
      value: role.user_id,
      label: role.full_name,
    }));

    return {
      options,
      hasMore: page < data.total_pages,
      additional: {
        page: page + 1,
      },
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

let loadedRejectReason = [];
const loadRejectReason = async (search) => {
  try {
    if (search && loadedRejectReason.length) {
      const filteredOptions = loadedRejectReason.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
      return {
        options: filteredOptions,
        hasMore: false,
      };
    }

    const res = await instance.get("/lookups/hireRejectReason");
    const { data } = res.data;
    const options = data.map((status) => ({
      value: status,
      label: status,
    }));

    loadedRejectReason = options;

    return {
      options,
      hasMore: false,
    };
  } catch (error) {
    return {
      options: [],
      hasMore: false,
    };
  }
};

const Hire = () => {
  const { formId } = useParams();

  const {
    hireApproved,
    setHireApproved,
    hireCategory,
    setHireCategory,
    hireBookingDate,
    setHireBookingDate,
    hireLeadSource,
    setHireLeadSource,
    hireCommenceDate,
    setHireCommenceDate,
    hireRefNo,
    setHireRefNo,
    hireSupplier,
    setHireSupplier,
    hireCompleteDate,
    setHireCompleteDate,
    outlay,
    setOutlay,
    sourceHirePaidDate,
    setSourceHirePaidDate,
    sourceHirePaidAmount,
    setSourceHirePaidAmount,
    naslHirePaidDateFE,
    setNaslHirePaidDateFE,
    naslHirePaidAmountFe,
    setNaslHirePaidAmountFe,
    naslHirePaidDateBE,
    setNaslHirePaidDateBE,
    naslHirePaidAmountBe,
    setNaslHirePaidAmountBe,
    hireAccessoriesPaymentDate,
    setHireAccessoriesPaymentDate,
    accessoriesPaymentAmount,
    setAccessoriesPaymentAmount,
    paServicingDate,
    setPaServicingDate,
    paServicingAmount,
    setPaServicingAmount,
    hireStatus,
    setHireStatus,
    rejectReason,
    setRejectReason,
    hireChaseDate,
    setHireChaseDate,
    hireTransferDate,
    setHireTranserDate,
    naslAccessoriesPaymentDate,
    setNaslAccessoriesPaymentDate,
    naslAccessoriesPaymentAmount,
    setNaslAccessoriesPaymentAmount,
    priority,
    setPriority,

    sourceHirePaidStatus,
    setSourceHirePaidStatus,
    naslHirePaidStatusFE,
    setNaslHirePaidStatusFE,
    naslHirePaidStatusBE,
    setNaslHirePaidStatusBE,
    accessoryPamentStatus,
    setAccessoryPamentStatus,
    servicingPaymentStatus,
    setservicingPaymentStatus,
    naslAccessoryPamentStatus,
    setNaslAccessoryPamentStatus,

    // -----note's values-------
    isAddNotActive,
    setIsAddNotActive,
    isAddAsReminderActive,
    setIsAddAsReminderActive,
    isSendEmailActive,
    setIsSendEmailActive,
    isSendSMSActive,
    setIsSendSMSActive,

    noteDescription,
    setNoteDescription,
    addNoteTo,
    setAddNoteTo,
    addAsReminderDate,
    setAddAsReminderDate,
    sendEmailTo,
    setSendEmailTo,
    sendSMSTo,
    setSendSMSTo,
    sendSMSMobile,
    setSendSMSMobile,
  } = useHireContext();

  const [validationErrors, setValidationErrors] = useState({
    mobileNumber: "",
    clientNumber: "",
  });

  const clearNoteFields = () => {
    setIsAddNotActive(false);
    setIsAddAsReminderActive(false);
    setIsSendEmailActive(false);
    setIsSendSMSActive(false);
    setNoteDescription("");
    setAddNoteTo("");
    setAddAsReminderDate(null);
    setSendEmailTo("");
    setSendSMSTo("");
    setSendSMSMobile({
      clientNumber: "",
      mobileNumber: "",
    });
  };

  const { handleError } = useError();
  const [isUpdating, setIsUpdating] = useState(false);

  const { formData, getNotes } = useUpdateFormContext();

  const setFields = () => {
    if (!formData || !formData.hire) return;
    const { hire } = formData;

    setHireApproved({ value: hire.approved_status, label: hire.approved_status });
    setHireCategory({ value: hire.category, label: hire.category });
    setHireLeadSource({ value: hire.lead_source_id, label: hire.lead_source_name });
    setHireBookingDate(toDateObj(hire.on_hire_date));
    setHireCommenceDate(toDateObj(hire.commence_date));
    setHireRefNo(hire.ref_no);
    setHireSupplier({ value: hire.supplier_id, label: hire.supplier_name });
    setHireCompleteDate(toDateObj(hire.complete_date));
    setOutlay({ value: hire.outlay_status, label: hire.outlay_status });
    setSourceHirePaidDate(toDateObj(hire.source_paid_date));
    setSourceHirePaidAmount(hire.source_paid_amount);
    setNaslHirePaidDateFE(toDateObj(hire.fe_paid_date));
    setNaslHirePaidAmountFe(hire.fe_paid_amount);
    setNaslHirePaidDateBE(toDateObj(hire.be_paid_date));
    setNaslHirePaidAmountBe(hire.be_paid_amount);
    setHireAccessoriesPaymentDate(toDateObj(hire.accessories_paid_date));
    setAccessoriesPaymentAmount(hire.accessories_paid_amount);
    setPaServicingDate(toDateObj(hire.pa_paid_date));
    setPaServicingAmount(hire.pa_paid_amount);
    setHireStatus({ value: hire.hire_status, label: hire.hire_status });
    setRejectReason({ value: hire.rejection_reason, label: hire.rejection_reason });
    setHireChaseDate(toDateObj(hire.chase_date));
    setHireTranserDate(toDateObj(hire.transfer_date));
    setNaslAccessoriesPaymentDate(toDateObj(hire.nasl_accessories_paid_date));
    setNaslAccessoriesPaymentAmount(hire.nasl_accessories_paid_amount);
    setPriority(hire.hire_priority);

    // Status
    setSourceHirePaidStatus(hire.source_paid_status === "Yes");
    setNaslHirePaidStatusFE(hire.fe_paid_status === "Yes");
    setNaslHirePaidStatusBE(hire.be_paid_status === "Yes");
    setAccessoryPamentStatus(hire.accessories_paid_status === "Yes");
    setservicingPaymentStatus(hire.pa_paid_status === "Yes");
    setNaslAccessoryPamentStatus(hire.nasl_accessories_paid_status === "Yes");
  };

  useEffect(() => {
    setFields();
  }, [formData]);

  const updateHandler = async () => {
    let isInvalid = false;

    if (!validatePhoneNumber(sendSMSMobile.clientNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "Invalid client number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, clientNumber: "" }));
    }

    if (!validatePhoneNumber(sendSMSMobile.mobileNumber)) {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "Invalid mobile number" }));
      isInvalid = true;
    } else {
      setValidationErrors((prevErrors) => ({ ...prevErrors, mobileNumber: "" }));
    }

    if (isInvalid) return;

    try {
      setIsUpdating(true);
      const payload = {
        approved_status: hireApproved?.value || "",
        category: hireCategory?.value || "",
        commence_date: toYYYYMMDD(hireCommenceDate),
        ref_no: hireRefNo,
        supplier_id: hireSupplier?.value || "",
        outlay_status: outlay?.value || "",
        source_paid_date: toYYYYMMDD(sourceHirePaidDate),
        source_paid_status: sourceHirePaidStatus ? "Yes" : "No",
        source_paid_amount: sourceHirePaidAmount,
        fe_paid_date: toYYYYMMDD(naslHirePaidDateFE),
        fe_paid_status: naslHirePaidStatusFE ? "Yes" : "No",
        fe_paid_amount: naslHirePaidAmountFe,
        be_paid_date: toYYYYMMDD(naslHirePaidDateBE),
        be_paid_status: naslHirePaidStatusBE ? "Yes" : "No",
        be_paid_amount: naslHirePaidAmountBe,
        accessories_paid_date: toYYYYMMDD(hireAccessoriesPaymentDate),
        accessories_paid_status: accessoryPamentStatus ? "Yes" : "No",
        accessories_paid_amount: accessoriesPaymentAmount,
        pa_paid_date: toYYYYMMDD(paServicingDate),
        pa_paid_status: servicingPaymentStatus ? "Yes" : "No",
        pa_paid_amount: accessoriesPaymentAmount,
        hire_status: hireStatus?.value || "",
        chase_date: toYYYYMMDD(hireChaseDate),
        transfer_date: toYYYYMMDD(hireTransferDate),
        nasl_accessories_paid_date: toYYYYMMDD(naslAccessoriesPaymentDate),
        nasl_accessories_paid_status: naslAccessoryPamentStatus ? "Yes" : "No",
        nasl_accessories_paid_amount: naslAccessoriesPaymentAmount,
        hire_priority: priority || "",
        on_hire_date: toYYYYMMDD(hireBookingDate),
        rejection_reason: rejectReason?.value || "",
        note: {
          note_type: "Hire",
          description: noteDescription,
          display_to_deo: addNoteTo.displayToDEO ? 1 : 0,
          display_to_client: addNoteTo.displayToClient ? 1 : 0,
          reminder_date: toYYYYMMDD(addAsReminderDate),
          send_email_source: sendEmailTo.source ? 1 : 0,
          send_email_client: sendEmailTo.client ? 1 : 0,
          send_sms_to: sendSMSTo,
          send_sms_mobile: sendSMSTo === "client" ? sendSMSMobile.clientNumber : sendSMSMobile.mobileNumber,
        },
      };

      if (hireLeadSource) payload.lead_source_id = +hireLeadSource.value;

      await instance.put(`/case/updateHire/${formId}`, payload);
      toastr.success("Success", "Hire updated successfully");
      clearNoteFields();
      getNotes();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUpdating(false);
    }
  };

  return (
    <Form>
      <Row>
        <Col md={4}>
          <FormGroup>
            <Label for="hireApprovedSelect">Hire Approved</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="currentStatusSelect"
              id="currentStatusSelect"
              loadOptions={loadHireApprovedOption}
              value={hireApproved}
              onChange={(selected) => setHireApproved(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireCategorySelect">Hire Category</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="hireCategorySelect"
              id="hireCategorySelect"
              loadOptions={loadHireCategories}
              value={hireCategory}
              onChange={(selected) => setHireCategory(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireBookingInDate">Hire Booking In Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={hireBookingDate} setStartDate={setHireBookingDate} id={"hireBookingDate"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireLeadSource">Hire Lead Source</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="hireLeadSource"
              id="hireLeadSource"
              loadOptions={loadLeadSourceOptions}
              additional={{
                page: 1,
              }}
              value={hireLeadSource}
              debounceTimeout={300}
              onChange={(selected) => setHireLeadSource(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireCommenceDate">Hire Commence Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={hireCommenceDate} setStartDate={setHireCommenceDate} id={"hireCommenceDate"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireReferenceNo">Hire Reference #</Label>
            <Input type="text" id="hireReferenceNo" value={hireRefNo} onChange={(e) => setHireRefNo(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireSupplier">Hire Supplier</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="hireSupplier"
              id="hireSupplier"
              loadOptions={loadHireSuppliers}
              additional={{
                page: 1,
              }}
              value={hireSupplier}
              debounceTimeout={300}
              onChange={(selected) => setHireSupplier(selected)}
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireCompleteDate">Hire Complete Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={hireCompleteDate} setStartDate={setHireCompleteDate} id={"hireCompleteDate"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="outlay">Outlay</Label>
            <Select className="basic-single" classNamePrefix="select" name="outlay" value={outlay} options={yesOrNoOptions} onChange={(selectedOption) => setOutlay(selectedOption)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="sourceHirePaidDate">Source Hire Paid Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={sourceHirePaidDate} setStartDate={setSourceHirePaidDate} id={"sourceHirePaidDate"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="sourceHirePaidStatus" checked={sourceHirePaidStatus} onChange={(e) => setSourceHirePaidStatus(e.target.checked)} />
              <Label for="sourceHirePaidStatus">Source Hire Paid Status</Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="sourceHirePaidAmount">Source Hire Paid Amount</Label>
            <Input type="text" id="sourceHirePaidAmount" value={sourceHirePaidAmount} onChange={(e) => setSourceHirePaidAmount(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="naslHirePaidDateFE">NASL Hire Paid Date (FE)</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={naslHirePaidDateFE} setStartDate={setNaslHirePaidDateFE} id={"naslHirePaidDateFE"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="naslHirePaidStatusFE" checked={naslHirePaidStatusFE} onChange={(e) => setNaslHirePaidStatusFE(e.target.checked)} />
              <Label for="naslHirePaidStatusFE">NASL Hire Paid Status (FE)</Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="naslHirePaidAmountFE">NASL Hire Paid Amount (FE)</Label>
            <Input type="text" id="naslHirePaidAmountFE" value={naslHirePaidAmountFe} onChange={(e) => setNaslHirePaidAmountFe(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="naslHirePaidDateBE">NASL Hire Paid Date (BE)</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={naslHirePaidDateBE} setStartDate={setNaslHirePaidDateBE} id={"naslHirePaidDateBE"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="naslHirePaidStatusBE" checked={naslHirePaidStatusBE} onChange={(e) => setNaslHirePaidStatusBE(e.target.checked)} />
              <Label for="naslHirePaidStatusBE">NASL Hire Paid Status (BE)</Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="naslHirePaidAmountBE">NASL Hire Paid Amount (BE)</Label>
            <Input type="text" id="naslHirePaidAmountBE" value={naslHirePaidAmountBe} onChange={(e) => setNaslHirePaidAmountBe(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireAccessoriesPaymentDate">Hire Accessories Payment Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={hireAccessoriesPaymentDate} setStartDate={setHireAccessoriesPaymentDate} id={"hireAccessoriesPaymentDate"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="accessoryPamentStatus" checked={accessoryPamentStatus} onChange={(e) => setAccessoryPamentStatus(e.target.checked)} />
              <Label for="accessoryPamentStatus">Accessory Payment Status</Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireAccessoriesPaymentAmount">Accessories Payment Amount</Label>
            <Input type="text" id="hireAccessoriesPaymentAmount" value={accessoriesPaymentAmount} onChange={(e) => setAccessoriesPaymentAmount(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="paServicingDate">PA Servicing Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={paServicingDate} setStartDate={setPaServicingDate} id={"paServicingDate"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="servicingPaymentStatus" checked={servicingPaymentStatus} onChange={(e) => setservicingPaymentStatus(e.target.checked)} />
              <Label for="servicingPaymentStatus">Servicing Payment Status</Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="paServicingAmount">PA Servicing Amount</Label>
            <Input type="text" id="paServicingAmount" value={paServicingAmount} onChange={(e) => setPaServicingAmount(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireChaseDate">Hire Chase Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={hireChaseDate} setStartDate={setHireChaseDate} id={"hireChaseDate"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireTransferDate">Hire Transfer Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={hireTransferDate} setStartDate={setHireTranserDate} id={"hireTransferDate"} />
            </Col>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="hireStatus">Hire Status</Label>
            <AsyncPaginate
              className="basic-single"
              classNamePrefix="select"
              isSearchable={true}
              name="hireStatus"
              id="hireStatus"
              loadOptions={loadHireStatus}
              value={hireStatus}
              onChange={(selected) => setHireStatus(selected)}
            />
          </FormGroup>
        </Col>
        {hireStatus?.value === "Reject" && (
          <Col md={4}>
            <FormGroup>
              <Label for="reasonOfRejection">Reason of Rejection</Label>
              <AsyncPaginate
                className="basic-single"
                classNamePrefix="select"
                isSearchable={true}
                name="reasonOfRejection"
                id="reasonOfRejection"
                loadOptions={loadRejectReason}
                value={rejectReason}
                onChange={(selected) => setRejectReason(selected)}
              />
            </FormGroup>
          </Col>
        )}
        <Col md={4}>
          <FormGroup>
            <Label for="naslAccessoriesPaymentDate">NASL Accessories Payment Date</Label>
            <Col md={6} className="p-0">
              <CustomDate startDate={naslAccessoriesPaymentDate} setStartDate={setNaslAccessoriesPaymentDate} id={"naslAccessoriesPaymentDate"} />
            </Col>
            <div className="ml-4">
              <Input type="checkbox" id="naslAccessoryPaymentStatus" checked={naslAccessoryPamentStatus} onChange={(e) => setNaslAccessoryPamentStatus(e.target.checked)} />
              <Label for="naslAccessoryPaymentStatus">NASL Accessory Payment Status</Label>
            </div>
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="naslAccessoriesPaymentAmount">NASL Accessories Payment Amount</Label>
            <Input type="text" id="naslAccessoriesPaymentAmount" value={naslAccessoriesPaymentAmount} onChange={(e) => setNaslAccessoriesPaymentAmount(e.target.value)} />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label>Priority</Label>

            <Row className="pl-4">
              <Col>
                <Label check>
                  <Input type="radio" name="priority" checked={priority === "High"} onChange={() => setPriority("High")} />
                  High
                </Label>
              </Col>
              <Col>
                <Label check>
                  <Input type="radio" name="priority" checked={priority === "Medium"} onChange={() => setPriority("Medium")} />
                  Medium
                </Label>
              </Col>
              <Col>
                <Label check>
                  <Input type="radio" name="priority" checked={priority === "Low"} onChange={() => setPriority("Low")} />
                  Low
                </Label>
              </Col>
            </Row>
          </FormGroup>
        </Col>
      </Row>

      <Notes
        noteDescription={noteDescription}
        setNoteDescription={setNoteDescription}
        sendSMSTo={sendSMSTo}
        setSendSMSTo={setSendSMSTo}
        addAsReminderDate={addAsReminderDate}
        setAddAsReminderDate={setAddAsReminderDate}
        sendEmailTo={sendEmailTo}
        setSendEmailTo={setSendEmailTo}
        addNoteTo={addNoteTo}
        setAddNoteTo={setAddNoteTo}
        sendSMSMobile={sendSMSMobile}
        setSendSMSMobile={setSendSMSMobile}
        isAddNotActive={isAddNotActive}
        setIsAddNotActive={setIsAddNotActive}
        isAddAsReminderActive={isAddAsReminderActive}
        setIsAddAsReminderActive={setIsAddAsReminderActive}
        isSendEmailActive={isSendEmailActive}
        setIsSendEmailActive={setIsSendEmailActive}
        isSendSMSActive={isSendSMSActive}
        setIsSendSMSActive={setIsSendSMSActive}
        validationErrors={validationErrors}
        setValidationErrors={setValidationErrors}
      />

      <div className="d-flex justify-content-end">
        <ParamsLink to="/administration/cases" className="mr-2">
          <Button color="primary">Exit</Button>
        </ParamsLink>
        <LoadingButton onClick={updateHandler} isLoading={isUpdating}>
          Update
        </LoadingButton>
      </div>
    </Form>
  );
};

export default Hire;
