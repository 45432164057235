import React, { memo } from "react";
import { useForm, Controller } from "react-hook-form";
import { Button, Col, CustomInput, Form, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { Link } from "react-router-dom";
import * as yup from "yup";
import { RequiredLabel } from "../../../components";

const schema = yup.object().shape({
  name: yup.string().required("Name is required"),
  ipAddress1: yup.string().required("IP Address is required"),
});

const IPGroupsForm = memo(({ onSubmit, initialData, mode = "add" }) => {
  const { handleSubmit, control, formState } = useForm({
    resolver: async (data) => {
      try {
        await schema.validate(data, { abortEarly: false });
        return { values: data, errors: {} };
      } catch (errors) {
        return { values: {}, errors: yupToFormErrors(errors) };
      }
    },
    defaultValues: {
      name: initialData?.name || "",
      activeStatus: initialData?.activeStatus || false,
      ipAddress1: initialData?.ipAddress1 || "",
      ipAddress2: initialData?.ipAddress2 || "",
      ipAddress3: initialData?.ipAddress3 || "",
    },
  });

  const yupToFormErrors = (yupErrors) => {
    return yupErrors.inner.reduce((errors, err) => {
      return {
        ...errors,
        [err.path]: {
          message: err.message,
          type: "validation",
        },
      };
    }, {});
  };

  const handleFormSubmit = async (data) => {
    await onSubmit(data);
  };

  return (
    <Form onSubmit={handleSubmit(handleFormSubmit)}>
      <Row>
        <Col md={6}>
          <FormGroup>
            <RequiredLabel htmlFor="name">Name</RequiredLabel>
            <Controller
              render={({ field, fieldState }) => (
                <>
                  <Input {...field} autoComplete="off" invalid={!!fieldState.error} />
                  {fieldState.error && <small className="text-danger">{fieldState.error.message}</small>}
                </>
              )}
              control={control}
              name="name"
            />
          </FormGroup>
        </Col>
        <Col md={6}>
          <FormGroup>
            <Label for="activeStatus">Active Status</Label>
            <Controller
              render={({ field }) => (
                <CustomInput type="switch" id="activeStatus" name="activeStatus" label={field.value ? "Active" : "Inactive"} checked={field.value} onChange={(e) => field.onChange(e.target.checked)} />
              )}
              control={control}
              name="activeStatus"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={4}>
          <FormGroup>
            <RequiredLabel htmlFor="ipAddress1">IP Address 1</RequiredLabel>
            <Controller
              render={({ field, fieldState }) => (
                <>
                  <Input id="ipAddress1" autoComplete="off" name="ipAddress1" value={field.value} onChange={(e) => field.onChange(e.target.value)} invalid={!!fieldState.error} />
                  {fieldState.error && <small className="text-danger">{fieldState.error.message}</small>}
                </>
              )}
              control={control}
              name="ipAddress1"
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="ipAddress2">IP Address 2</Label>
            <Controller
              render={({ field }) => <Input id="ipAddress2" autoComplete="off" name="ipAddress2" value={field.value} onChange={(e) => field.onChange(e.target.value)} />}
              control={control}
              name="ipAddress2"
            />
          </FormGroup>
        </Col>
        <Col md={4}>
          <FormGroup>
            <Label for="ipAddress3">IP Address 3</Label>
            <Controller
              render={({ field }) => <Input id="ipAddress3" autoComplete="off" name="ipAddress3" value={field.value} onChange={(e) => field.onChange(e.target.value)} />}
              control={control}
              name="ipAddress3"
            />
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col md={12} className="d-flex justify-content-end">
          <Link to="/security/IPGroups">
            <Button color="primary" type="button" className="mr-2">
              Exit
            </Button>
          </Link>

          <Button color="primary" type="submit" disabled={formState.isSubmitting}>
            {formState.isSubmitting && <Spinner size="sm" color="#fff" className="mr-2 mb-1" />}
            <span>{mode === "add" ? "Add" : "Save"}</span>
          </Button>
        </Col>
      </Row>
    </Form>
  );
});

export default IPGroupsForm;
