import { faEdit, faPen, faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { memo, useState } from "react";
import Select from "react-select";
import { Button, Card, CardBody, CardTitle, FormGroup, Input, Label, Modal, ModalBody, ModalHeader } from "reactstrap";

const NoteForm = memo(({ mode = "add", toggleModal = () => {} }) => {
  return (
    <>
      <FormGroup>
        <Label>Select Source</Label>
        <Select options={[{ value: 1, label: "test" }]} className="basic-single" classNamePrefix="select" />
      </FormGroup>
      <FormGroup>
        <Label for="note">Note</Label>
        <Input type="textarea" id="note" placeholder="Enter Note" />
      </FormGroup>
      <FormGroup>
        <Label className="ml-4">
          <Input type="checkbox" />
          <span>Add Reminder</span>
        </Label>
        <Label className="ml-6">
          <Input type="checkbox" />
          <span>Chase</span>
        </Label>
      </FormGroup>

      <div className="d-flex justify-content-end">
        <Button color="primary" outline onClick={toggleModal} className="mr-2">
          Cancle
        </Button>
        <Button color="primary">
          {mode === "add" ? (
            <>
              <FontAwesomeIcon icon={faPlus} /> <span>Add</span>
            </>
          ) : (
            <>
              <FontAwesomeIcon icon={faPen} /> <span>Update</span>
            </>
          )}
        </Button>
      </div>
    </>
  );
});

const NoteItem = memo(({ dateTime, noteTo, chase, note }) => {
  const [editModalIsOpen, setEditModalIsOpen] = useState(false);
  const toggleEditModal = () => setEditModalIsOpen(!editModalIsOpen);

  const [deleteModalIsOpen, setDeleteModalIsOpen] = useState(false);
  const toggleDeleteModal = () => setDeleteModalIsOpen(!deleteModalIsOpen);

  return (
    <Card className="border mr-2">
      <CardBody>
        <CardTitle tag="h5" className="d-flex justify-content-between">
          <span>{dateTime}</span>
          <span>
            <Button color="primary" onClick={toggleEditModal}>
              <FontAwesomeIcon icon={faEdit} />
            </Button>
            <Button color="danger" className="ml-2" onClick={toggleDeleteModal}>
              <FontAwesomeIcon icon={faTrash} />
            </Button>
          </span>
        </CardTitle>

        <h6>Note To: {noteTo}</h6>
        <p>Chase: {chase}</p>
        <p>{note}</p>
      </CardBody>

      <Modal isOpen={editModalIsOpen} toggle={toggleEditModal} centered>
        <ModalHeader toggle={toggleEditModal}>Edit Note</ModalHeader>
        <ModalBody>
          <NoteForm mode="edit" toggleModal={toggleEditModal} />
        </ModalBody>
      </Modal>

      <Modal isOpen={deleteModalIsOpen} toggle={toggleDeleteModal} centered>
        <ModalHeader toggle={toggleDeleteModal}>Delete Note</ModalHeader>
        <ModalBody>
          <h4 className="my-4">Are you sure you want to delete this note?</h4>

          <div className="d-flex justify-content-end">
            <Button color="primary" outline onClick={toggleDeleteModal} className="mr-2">
              Cancle
            </Button>
            <Button color="danger">
              <FontAwesomeIcon icon={faTrash} /> Delete
            </Button>
          </div>
        </ModalBody>
      </Modal>
    </Card>
  );
});

const PersonalNotes = memo(({ maxHeight = "" }) => {
  const [addModalIsOpen, setAddModalIsOpen] = useState(false);
  const toggleAddModal = () => setAddModalIsOpen(!addModalIsOpen);

  return (
    <Card>
      <CardBody>
        <div className="d-flex justify-content-between align-items-center py-2">
          <CardTitle tag="h5">Personal Notes</CardTitle>
          <Button color="primary" className="mr-4" onClick={toggleAddModal}>
            <FontAwesomeIcon icon={faPlus} /> Add Note
          </Button>
        </div>

        <div className={`overflow-auto ${maxHeight || "max-h-572"}`}>
          <NoteItem
            dateTime="20-02-2024 06:20"
            noteTo="test garage"
            chase="no"
            note="Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum distinctio doloremque asperiores consectetur doloribus? Molestiae in facere labore eaque perferendis?"
          />
          <NoteItem
            dateTime="20-02-2024 06:20"
            noteTo="test garage"
            chase="no"
            note="Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum distinctio doloremque asperiores consectetur doloribus? Molestiae in facere labore eaque perferendis?"
          />
          <NoteItem
            dateTime="20-02-2024 06:20"
            noteTo="test garage"
            chase="no"
            note="Lorem ipsum dolor sit amet consectetur adipisicing elit. Rerum distinctio doloremque asperiores consectetur doloribus? Molestiae in facere labore eaque perferendis?"
          />
        </div>
      </CardBody>

      <Modal isOpen={addModalIsOpen} toggle={toggleAddModal} centered>
        <ModalHeader toggle={toggleAddModal}>Add Note</ModalHeader>
        <ModalBody>
          <NoteForm toggleModal={toggleAddModal} />
        </ModalBody>
      </Modal>
    </Card>
  );
});

export default PersonalNotes;
