import React, { useEffect, useState } from "react";
import { Col, CustomInput, Form, FormGroup, Input, Label, Row } from "reactstrap";
import { useClaimFormContext } from "../../../context/administration/claimForm";
import Select from "react-select";
import { booleanOptions } from "../ClaimForm/ClientDetails";
import instance from "../../../axiosInstance";
import useError from "../../../hooks/useError";
import { AsyncPaginate } from "react-select-async-paginate";

const Additional = ({ caseId }) => {
  //traching form change
  const [formChange, setFormChange] = useState(false)
  const { handleError } = useError();

  const { additional_information, setAdditionalInformation, about_nasl, setAboutNasl, can_transfer_solicitor, setCanTransferSolicitor, can_share_detail, setCanShareDetail } = useClaimFormContext();

  let fetchedAboutNaslOptions = [];
  const fetchaboutNaslOptions = async (search) => {
    try {
      if (search && fetchedAboutNaslOptions.length) {
        const filteredOptions = fetchedAboutNaslOptions.filter((option) => option.label.toLowerCase().includes(search.toLowerCase()));
        return {
          options: filteredOptions,
          hasMore: false,
        };
      }

      const res = await instance.get("/lookups/aboutNASL");
      const { data } = res.data;
      const options = data.map((status) => ({
        value: status,
        label: status,
      }));

      fetchedAboutNaslOptions = options;

      return {
        options,
        hasMore: false,
      };
    } catch (error) {
      return {
        options: [],
        hasMore: false,
      };
    }
  };



  // ----------------------------- Fetching Data ------------------------------
  useEffect(() => {
    const getData = async () => {
      try {
        const response = await instance.get(`case/view/${caseId}`);
        const data = response.data.data.additional_detail;

        const selectedBooleanValue = (field) => {
          return booleanOptions.find((option) => option.value === field);
        };

        if (data) {
          setAdditionalInformation(data.additional_information);
          setAboutNasl({ value: data.about_nasl, label: data.about_nasl });
          setCanTransferSolicitor(selectedBooleanValue(data.can_transfer_solicitor));
          setCanShareDetail(selectedBooleanValue(data.can_share_detail));
        }
      } catch (err) {
        handleError(err);
      }
    };

    getData();
  }, []);

  const handleFormChange = () => {
    setFormChange(true)
  }

  return (
    <Form>
      <h4>Additional Information/Passenger Details</h4>

      <Row>
        <Col md={8}>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="additionalInformation"> Additional Information </Label>
                <Input
                  type="textarea"
                  autoComplete="off"
                  rows="2"
                  id="additionalInformation"
                  name="additionalInformation"
                  value={additional_information}
                  onChange={(e) => {
                    setAdditionalInformation(e.target.value);
                    handleFormChange();
                  }}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="canShareDetail">Are you happy for us to share your details with our third parties we work with in relation to your claim?</Label>
                <Select
                  id="canShareDetail"
                  name="canShareDetail"
                  className="basic-single"
                  classNamePrefix="select"
                  value={can_share_detail}
                  onChange={(value) => {
                    setCanShareDetail(value);
                    handleFormChange();
                  }}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="canTransferSolicitor">Are you happy for us to transfer to our solicitors?</Label>
                <Select
                  id="canTransferSolicitor"
                  name="canTransferSolicitor"
                  className="basic-single"
                  classNamePrefix="select"
                  value={can_transfer_solicitor}
                  onChange={(value) => {
                    setCanTransferSolicitor(value);
                    handleFormChange();
                  }}
                  options={booleanOptions}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="aboutNasl"> Where did you hear about NASL? </Label>
                <AsyncPaginate
                  className="basic-single"
                  classNamePrefix="select"
                  isSearchable={true}
                  name="aboutNasl"
                  id="aboutNasl"
                  loadOptions={fetchaboutNaslOptions}
                  value={about_nasl}
                  onChange={(selected) => setAboutNasl(selected)}
                />   
              </FormGroup>
            </Col>
          </Row>
        </Col>
        <Col md={4}>
          <div style={{color: "#FF0000", lineHeight: "12px"}}>
            <b>DATA PROTECTION ACT</b>
            <p>For Data Protection Compliance, I would like to make you aware that all the information we have taken from you today will be stored securely and the companies with whom we share details with are also data protection compliant so you can rest assured that your best interests have been looked after at all times</p>
          </div>
          <div style={{color: "#FF0000", lineHeight: "12px"}}>
            <b>LEI DE PROTEÇÃO DE DADOS</b>
            <p>Conforme a lei de proteção de dados, gostaria de informá-lo de que todas as informações que recebemos de você hoje serão armazenadas com segurança e as empresas com as quais compartilhamos detalhes também estão em acordo com a proteção de dados para que você possa ter certeza de que seus dados estão sendo cuidados em todos os momentos.</p>
          </div>        
        </Col>
      </Row>
    </Form>
  );
};

export default Additional;