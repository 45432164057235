import React from "react";
import { faChartLine, faLock, faShieldAlt, faSignInAlt, faUserTie } from "@fortawesome/free-solid-svg-icons";
import NewInsurance from "../pages/Administration/NewInsurance";

// Auth
const AccountRecovery = React.lazy(() => import("../pages/auth/AccountRecovery"));
const Login = React.lazy(() => import("../pages/auth/Login"));
const Page404 = React.lazy(() => import("../pages/auth/Page404"));
const Page500 = React.lazy(() => import("../pages/auth/Page500"));

const AddNewCase = React.lazy(() => import("../pages/Administration/AddNewCase"));
const ClaimForm = React.lazy(() => import("../pages/Administration/ClaimForm"));
const ClaimFormProceed = React.lazy(() => import("../pages/Administration/ClaimFormProceed"));
const NewCases = React.lazy(() => import("../pages/Administration/NewCases"));
const SelfBilling = React.lazy(() => import("../pages/Administration/SelfBilling"));
const Try = React.lazy(() => import("../pages/Administration/Try"));
const UpdateForm = React.lazy(() => import("../pages/Administration/UpdateForm"));
const UpdateFormProceed = React.lazy(() => import("../pages/Administration/UpdateFormProceed"));
const Dashboard = React.lazy(() => import("../pages/Dashboard"));
const UploadData = React.lazy(() => import("../pages/Insurance/UploadData"));
const Profile = React.lazy(() => import("../pages/Profile"));
const AddIPGroup = React.lazy(() => import("../pages/Security/AddIPGroup"));
const AddRole = React.lazy(() => import("../pages/Security/AddRole"));
const AddUser = React.lazy(() => import("../pages/Security/AddUser"));
const EditIPGroup = React.lazy(() => import("../pages/Security/EditIPGroup"));
const EditRole = React.lazy(() => import("../pages/Security/EditRole"));
const EditUser = React.lazy(() => import("../pages/Security/EditUser"));
const IpGroups = React.lazy(() => import("../pages/Security/IPGroup"));
const Roles = React.lazy(() => import("../pages/Security/Roles"));
const UserProfile = React.lazy(() => import("../pages/Security/UserProfile"));
const Users = React.lazy(() => import("../pages/Security/Users"));
const ResetPassword = React.lazy(() => import("../pages/auth/ResetPassword"));
const SelfBillingView = React.lazy(() => import("../pages/Administration/SelfBillingView"));
const ViewCase = React.lazy(() => import("../pages/Administration/ViewCase"));

const dashboardRoute = {
  path: "/",
  name: "Dashboard",
  icon: faChartLine,
  component: Dashboard,
};

const profileRoute = {
  path: "/profile",
  name: null,
  component: Profile,
};

const securityRoutes = {
  path: "/security",
  name: "Security",
  icon: faLock,
  children: [
    {
      path: "/security/roles",
      name: "Roles",
      component: Roles,
    },
    {
      path: "/security/roles/addRole",
      name: null,
      component: AddRole,
    },
    {
      path: "/security/roles/:id/edit",
      name: null,
      component: EditRole,
    },
    {
      path: "/security/users",
      name: "Users",
      component: Users,
    },
    {
      path: "/security/users/addUser",
      name: null,
      component: AddUser,
    },
    {
      path: "/security/users/:id",
      name: null,
      component: UserProfile,
    },
    {
      path: "/security/users/:id/edit",
      name: null,
      component: EditUser,
    },
    {
      path: "/security/IPGroups",
      name: "IP Groups",
      component: IpGroups,
    },
    {
      path: "/security/IPGroups/addIPGroup",
      name: null,
      component: AddIPGroup,
    },
    {
      path: "/security/IPGroups/:id/edit",
      name: null,
      component: EditIPGroup,
    },
  ],
};

const administrationRoutes = {
  path: "/administration",
  name: "Administration",
  icon: faUserTie,
  children: [
    {
      path: "/administration/addNewCase",
      name: "Add New Case",
      component: AddNewCase,
    },
    {
      path: "/administration/cases",
      name: "Cases List",
      component: NewCases,
    },
    {
      path: "/try",
      name: null,
      component: Try,
    },
    {
      path: "/administration/claimForm",
      name: null,
      component: ClaimForm,
    },
    {
      path: "/administration/cases/view/:id",
      name: null,
      component: ViewCase,
    },
    {
      path: "/administration/cases/:formId",
      name: null,
      component: ClaimFormProceed,
    },
    {
      path: "/administration/updateForm",
      name: null,
      component: UpdateForm,
    },
    {
      path: "/administration/cases/:formId/edit",
      name: null,
      component: UpdateFormProceed,
    },
    {
      path: "/administration/selfBilling",
      name: "Self Billing",
      component: SelfBilling,
    },
    {
      path: "/administration/selfBilling/:id",
      name: null,
      component: SelfBillingView,
    },
    {
      path: "/administration/newInsurance",
      name: "New Insurance",
      component: NewInsurance,
    },
  ],
};

const insuranceRoutes = {
  path: "/insurance",
  name: "Insurance",
  icon: faShieldAlt,
  children: [
    {
      path: "/insurance/uploadData",
      name: "Upload Data",
      component: UploadData,
    },
  ],
};

const authRoutes = {
  path: "/auth",
  name: "Auth",
  icon: faSignInAlt,
  children: [
    {
      path: "/auth/login",
      name: "Login",
      component: Login,
    },
    {
      path: "/auth/account-recovery",
      name: "Account Recovery",
      component: AccountRecovery,
    },
    {
      path: "/auth/reset-password",
      name: "Reset Password",
      component: ResetPassword,
    },
    {
      path: "/auth/404",
      name: "404 Page",
      component: Page404,
    },
    {
      path: "/auth/500",
      name: "500 Page",
      component: Page500,
    },
  ],
};

// Dashboard specific routes
export const dashboard = [dashboardRoute, profileRoute, securityRoutes, administrationRoutes, insuranceRoutes];

// Auth specific routes
export const page = [authRoutes];

// All routes
export default [dashboardRoute, securityRoutes, administrationRoutes, insuranceRoutes];
