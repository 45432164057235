import React, { memo, useState } from "react";
import Select from "react-select";
import { Card, CardBody, CardTitle, Col, Input, Label, ListGroup, ListGroupItem, Row } from "reactstrap";
import CustomDate from "../UI/forms/CustomDate";
import Pagination from "../UI/Pagination";

const itemsOptions = [
  {
    value: 10,
    label: 10,
  },
  {
    value: 25,
    label: 25,
  },
  {
    value: 50,
    label: 50,
  },
  {
    value: 100,
    label: 100,
  },
];

const Cases = memo(() => {
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);

  const [paginationInfo, setPaginationInfo] = useState({
    currentPage: 2,
    totalPages: 24,
    totalRecords: 123,
    limit: 10,
  });

  return (
    <Card>
      <CardBody>
        <CardTitle tag="h5">Cases</CardTitle>

        <Row>
          <Col sm="4">
            <Input type="text" placeholder="Search..." />
          </Col>
          <Col sm="8" className="d-flex justify-content-end">
            <div className="w-86">
              <Select className="basic-single" classNamePrefix="select" options={itemsOptions} value={{ value: 10, label: 10 }} />
            </div>
          </Col>
        </Row>

        {/* <Row className="mt-2">
          <Col sm="4">
            <Label>From Date</Label>
            <CustomDate startDate={fromDate} setStartDate={setFromDate} />
          </Col>
          <Col sm="4">
            <Label>To Date</Label>
            <CustomDate startDate={toDate} setStartDate={setToDate} />
          </Col>
          <Col sm="4">
            <Label>Filter By Source</Label>
            <Select className="basic-single" classNamePrefix="select" options={itemsOptions} value={{ value: 10, label: 10 }} />
          </Col>
        </Row> */}

        <ListGroup flush className="mt-2">
          <ListGroupItem>
            <div className="d-flex align-items-center justify-content-between">
              <span>Mr. Muhammad Gulam Ali</span>
              <span className="text-muted">NASL: 29764</span>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex align-items-center justify-content-between">
              <span>Mr. Muhammad Gulam Ali</span>
              <span className="text-muted">NASL: 29764</span>
            </div>
          </ListGroupItem>
          <ListGroupItem>
            <div className="d-flex align-items-center justify-content-between">
              <span>Mr. Muhammad Gulam Ali</span>
              <span className="text-muted">NASL: 29764</span>
            </div>
          </ListGroupItem>
        </ListGroup>
      </CardBody>
      <Pagination paginationInfo={paginationInfo} />
    </Card>
  );
});

export default Cases;
