import React, { useEffect } from "react";
import { useUpdateFormContext } from "../../../context/administration/updateForm";

const UpdateFormTabs = ({ caseId }) => {
  const { activeTab, setActiveTab, formData } = useUpdateFormContext();
  const serviceTypes = formData?.service_type || [];

  useEffect(() => {
    if (serviceTypes.length > 0) {
      setActiveTab(serviceTypes[0].toLowerCase());
    }
  }, [caseId]);

  return (
    <div>
      <ul className="update-form-tabs">
        {serviceTypes.map((service) => (
          <li key={service} className={`${activeTab === service.toLowerCase() ? "active" : ""}`} onClick={() => setActiveTab(service.toLowerCase())}>
            {service}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UpdateFormTabs;
